import { Grid, Skeleton } from 'antd';
import { formatMeetingDate } from '../utils';

export const NotesHeader = ({
  summary,
  startTime,
  endTime,
  domain,
}: {
  summary: string;
  startTime: string;
  endTime: string;
  domain: string;
}) => {
  const meetingTime = formatMeetingDate(startTime, endTime);
  const domainUrl = `https://logo.clearbit.com/${domain}`;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <>
      {!summary ? (
        <div style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton.Input
              active={true}
              size={'default'}
              style={{ width: '300px' }}
            />
            <Skeleton.Avatar active={true} size={'large'} shape={'circle'} />
          </div>
          <Skeleton.Input active={true} size={'small'} />
        </div>
      ) : (
        <div className="note-title-section">
          {!screens.lg && (
            <div>
              <img src={domainUrl} width="48" />
            </div>
          )}
          <div>
            <h1 className="meeting-title">{summary}</h1>
            <p className="meeting-time">{meetingTime}</p>
          </div>
          {screens.lg && (
            <div>
              <img src={domainUrl} width="48" />
            </div>
          )}
        </div>
      )}
    </>
  );
};
