import { Col, Row, Typography } from 'antd';
import { WinrateImages } from '../../assets/Images';
import React from 'react';

const UsersCountBlock: React.FC = () => {
  const {
    usersTabActive,
    invitedIcon,
    deactivatedIcon,
    orgChartIcon,
    activeIcon,
    rightArrowIcon,
  } = WinrateImages.UsersIcons;
  return (
    <Row
      justify="space-between"
      className="usercountblock mt-24"
      wrap={false}
      gutter={[16, 16]}
    >
      <Col flex={1} className="block" style={{ flex: 1 }}>
        <Typography.Text className="block-text">Users</Typography.Text>
        <Row justify="space-between" className="text-margin">
          <Typography.Title level={4} className="mb-0">
            12
          </Typography.Title>
          <img src={usersTabActive} alt="" />
        </Row>
      </Col>
      <Col flex={1} className="block" style={{ flex: 1 }}>
        <Typography.Text className="block-text">Active</Typography.Text>
        <Row justify="space-between" className="text-margin">
          <Typography.Title level={4} className="mb-0">
            12
          </Typography.Title>
          <img src={activeIcon} alt="" />
        </Row>
      </Col>
      <Col flex={1} className="block" style={{ flex: 1 }}>
        <Typography.Text className="block-text">Invited</Typography.Text>
        <Row justify="space-between" className="text-margin">
          <Typography.Title level={4} className="mb-0">
            12
          </Typography.Title>
          <img src={invitedIcon} alt="" />
        </Row>
      </Col>
      <Col flex={1} className="block" style={{ flex: 1 }}>
        <Typography.Text className="block-text">Deactivated</Typography.Text>
        <Row justify="space-between" className="text-margin">
          <Typography.Title level={4} className="mb-0">
            12
          </Typography.Title>
          <img src={deactivatedIcon} alt="" />
        </Row>
      </Col>
      <Col flex={1} className="org-block" style={{ flex: 1 }}>
        <Row justify="space-between">
          <div>
            <img src={orgChartIcon} alt="" />
            <Row className="text-margin">
              <Typography.Text className="fw-700">
                Organization Chart
              </Typography.Text>
            </Row>
          </div>
          <img src={rightArrowIcon} alt="" />
        </Row>
      </Col>
    </Row>
  );
};

export default UsersCountBlock;
