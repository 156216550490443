import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import SearchComponent from '../../../common/SearchComponent';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PrimaryButton from '../../../common/PrimaryButton';

const PermissionDetails: React.FC = () => {
  const location = useLocation();
  const { Panel } = Collapse;

  return (
    <>
      {location.state.data.name === 'Admin' ? (
        <div className="admin-permission-div mt-24">
          <Typography.Title level={5} className="fw-500">
            This group can access everything.
          </Typography.Title>
          <Typography.Text>
            This group can manage all users, modules, and settings.
          </Typography.Text>
        </div>
      ) : (
        <>
          <Row className="mt-24" justify="space-between">
            <SearchComponent placeholder="Search group name" />
            <div>
              <Checkbox>Expand all permissions</Checkbox>
              <Divider type="vertical" className="permission-divider" />
              <PrimaryButton text="save" />
            </div>
          </Row>
          <Collapse defaultActiveKey={['1']} ghost>
            <Panel
              header={
                <Typography.Title level={5} className="fw-500 mb-0">
                  Deal Rooms
                </Typography.Title>
              }
              key="1"
            >
              <Row justify="space-between" align="middle">
                <Typography.Text className="text-secondary">
                  The Deal Room is the feature for managing client opportunities
                  sourced from CRM systems. It allows users to create, view,
                  sort, <br />
                  filter, and search through various opportunities in a
                  structured table format.
                </Typography.Text>
                <Switch />
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={2}>
                  <Typography.Text>View </Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All deal rooms</Select.Option>
                    <Select.Option>Deal rooms their teams owns</Select.Option>
                    <Select.Option>Deal rooms they own</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={2}>
                  <Typography.Text>Edit </Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All deal rooms</Select.Option>
                    <Select.Option>Deal rooms their teams owns</Select.Option>
                    <Select.Option>Deal rooms they own</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={2}>
                  <Typography.Text>Archive</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All deal rooms</Select.Option>
                    <Select.Option>Deal rooms their teams owns</Select.Option>
                    <Select.Option>Deal rooms they own</Select.Option>
                  </Select>
                </Col>
              </Row>
            </Panel>
            <Divider />
            <Panel
              header={
                <Typography.Title level={5} className="fw-500 mb-0">
                  User Management
                </Typography.Title>
              }
              key="2"
            >
              <Row justify="space-between" align="middle">
                <Typography.Text className="text-secondary">
                  Add users, edit a user&apos;s permissions, deactivate users,
                  or remove existing users from your account.
                </Typography.Text>
                <Switch />
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>View Users</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All users</Select.Option>
                    <Select.Option>Users within thier teams</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>Edit Users</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All users</Select.Option>
                    <Select.Option>Users within thier teams</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>Remove Users</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All deal rooms</Select.Option>
                    <Select.Option>Deal rooms their teams owns</Select.Option>
                    <Select.Option>Deal rooms they own</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Divider className="panel-divider" />
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>View Teams</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All teams</Select.Option>
                    <Select.Option>Teams they own</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>Edit Teams</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All teams</Select.Option>
                    <Select.Option>Teams they own</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={3}>
                  <Typography.Text>Delete Teams</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
                <Col span={4}>
                  <Select>
                    <Select.Option>All teams</Select.Option>
                    <Select.Option>Teams they own</Select.Option>
                  </Select>
                </Col>
              </Row>
            </Panel>
            <Divider />
            <div className="int-row">
              <Typography.Title level={5} className="fw-500 mb-0 ">
                CRM Integration
              </Typography.Title>
              <Row justify="space-between" align="middle">
                <Typography.Text className="text-secondary">
                  Add users, edit a user&apos;s permissions, deactivate users,
                  or remove existing users from your account.
                </Typography.Text>
                <Switch />
              </Row>
            </div>
            <Divider className="mt-20" />
            <div className="int-row">
              <Typography.Title level={5} className="fw-500 mb-0 ">
                Slack Integration
              </Typography.Title>
              <Row justify="space-between" align="middle">
                <Typography.Text className="text-secondary">
                  Stay in the loop by syncing Slack with your workflow, so
                  you&apos;re always up-to-date on key activities.
                </Typography.Text>
                <Switch />
              </Row>
            </div>
            <Divider className="mt-20" />
            <Panel
              header={
                <Typography.Title level={5} className="fw-500 mb-0">
                  Setting Access
                </Typography.Title>
              }
              key="3"
            >
              <Row justify="space-between" align="middle">
                <Typography.Text className="text-secondary">
                  Control who can view and modify system settings with flexible
                  access permissions.
                </Typography.Text>
                <Switch />
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={6}>
                  <Typography.Text>Company Account Settings </Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={6}>
                  <Typography.Text>Slack Channel Name Settings</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={6}>
                  <Typography.Text>Deal Room Status Settings</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
              </Row>
              <Row className="mt-16 ml-10">
                <Col span={6}>
                  <Typography.Text>Chat Logs Settings</Typography.Text>
                </Col>
                <Col span={1}>
                  <Switch size="small" />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </>
      )}
    </>
  );
};

export default PermissionDetails;
