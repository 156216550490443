import { Modal, Row, Typography } from 'antd';
import { connectCalendar } from '../utils/IntegrationHelper';
import PrimaryButton from '../common/PrimaryButton';
import { WinrateImages } from '../assets/Images';

const ReAuthoRisedModal = ({
  showReAuthoRisedModal,
  setShowReAuthoRisedModal,
  users,
}: {
  showReAuthoRisedModal: boolean;
  setShowReAuthoRisedModal: (open: boolean) => void;
  users?: any;
}) => {
  return (
    <Modal
      open={showReAuthoRisedModal}
      onCancel={() => setShowReAuthoRisedModal(false)}
      footer={null}
      className="calendar-sync-modal"
      width={600}
    >
      <Row justify="center">
        <img src={WinrateImages.EventIcons.calendarSyncIcon} />
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Title level={3} className="title-text fw-500">
          Calendar <span className="sync-text">Sync</span> Needed
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text>
          It looks like your Google password has been changed. To continue
          syncing your
        </Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text>
          calendar and receiving meeting prep notes, please reconnect your
          calendar.
        </Typography.Text>
      </Row>
      <Row justify="center" className="mt-20">
        <PrimaryButton
          text="Connect Google Calendar"
          onClick={() => connectCalendar(users)}
        />
      </Row>
    </Modal>
  );
};

export default ReAuthoRisedModal;
