import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface UserInfo {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

interface UserDetails {
  organization_id: string;
  userInfo: UserInfo;
}

export interface authState {
  calendarToken: string;
  userID: string;
  meetingEvents: any;
  monthDate: any;
  showCurrentMonth: any;
  calendarMonth: any;
  selectedDate: any;
  selectedMonth: any;
  dateChange: boolean;
  startDate: string;
  endDate: string;
  checkedDomain: string[];
  userDetails: UserDetails | null;
  prepnoteSelectedDate: any;
  isCalenderVisible: boolean;
  payment_status: string;
  trialDays: string;
}

const initialState: authState = {
  calendarToken: '',
  userID: '',
  meetingEvents: [],
  monthDate: '',
  showCurrentMonth: null,
  calendarMonth: null,
  selectedDate: dayjs(),
  selectedMonth: dayjs(),
  dateChange: false,
  startDate: '',
  endDate: '',
  checkedDomain: [],
  userDetails: null,
  prepnoteSelectedDate: dayjs(),
  isCalenderVisible: true,
  payment_status: '',
  trialDays: '',
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCalendarToken: (state, { payload }) => {
      state.calendarToken = payload;
    },
    setUserID: (state, { payload }) => {
      state.userID = payload;
    },
    setMeetingEvents: (state, { payload }) => {
      state.meetingEvents = payload;
    },

    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
    setDateChange: (state, { payload }) => {
      state.dateChange = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    setCheckedDomain: (state, { payload }) => {
      state.checkedDomain = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setprepnoteSelectedDate: (state, { payload }) => {
      state.prepnoteSelectedDate = payload;
    },
    setIsCalenderVisible: (state, { payload }) => {
      state.isCalenderVisible = payload;
    },
    setPaymentStatus: (state, { payload }) => {
      state.payment_status = payload;
    },
    setTrialDays: (state, { payload }) => {
      state.trialDays = payload;
    },
  },
});

export const {
  setCalendarToken,
  setUserID,
  setMeetingEvents,
  setSelectedMonth,
  setSelectedDate,
  setDateChange,
  setStartDate,
  setEndDate,
  setCheckedDomain,
  setUserDetails,
  setprepnoteSelectedDate,
  setIsCalenderVisible,
  setPaymentStatus,
  setTrialDays,
} = authSlice.actions;
export default authSlice.reducer;
