import { Col, Divider, Flex, Input, message, Modal, Row, Skeleton } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { doGet, doPatch } from '../../../sdk/Events';
import { updatePassword } from 'aws-amplify/auth';
import Verify from '../../../Settings/components/Notifications/Verify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Success from '../../../Settings/components/Notifications/Success';
import Addnumber from '../../../Settings/components/Notifications/Addnumber';

const MyInfo: React.FC = () => {
  const [country, setCountry] = useState<string>('US');
  const [uploadLogoFile, setUplodLogoFile] = useState<RcFile | undefined>(
    undefined
  );
  const [uploadCompanyLogoFile, setUploadCompanyLogoFile] = useState<
    RcFile | undefined
  >();

  const [imageView, setImageView] = useState<string | undefined>(undefined);
  const [companyImageView, setCompanyImageView] = useState<
    string | undefined
  >();

  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [companyImageUrl, setCompanyImageUrl] = useState<string | null>(null);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [initialPhone, setInitialPhone] = useState<string>('');
  const [success, setSuccessModal] = useState(false);
  const [phoneadd, setPhoneadd] = useState(false);
  const [numModal, setnumModal] = useState(false);
  const [number, setnumber] = useState('');
  const [phone, setPhone] = useState<string | undefined>('');

  const [userInfo, setUserInfo] = useState<{
    id: string;
    fullName: string;
    title: string;
    email: string;
    phone: string;
    organization_id: string;
    organization_name: string;
    organization_domain: string;
    organization_logo: string;
  }>({
    id: '',
    fullName: '',
    title: '',
    email: '',
    phone: '',
    organization_id: '',
    organization_name: '',
    organization_domain: '',
    organization_logo: '',
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const companyFileInputRef = useRef<HTMLInputElement>(null);
  console.log(country);
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const handlePhoneChange = (value: string | undefined) => {
    setUserInfo((prev) => ({ ...prev, phone: value || '' }));
  };

  const handleUploadClick = (type: 'profile' | 'company') => {
    if (type === 'profile' && fileInputRef.current) {
      fileInputRef.current.click();
    } else if (type === 'company' && companyFileInputRef.current) {
      companyFileInputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'profile' | 'company'
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 500) {
        message.error(
          'The selected file exceeds the size limit of 500KB. Please upload a smaller file.'
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (type === 'profile') {
          setImageView(e?.target?.result as string);
          setUplodLogoFile(file as RcFile);
        } else if (type === 'company') {
          setCompanyImageView(e?.target?.result as string);
          setUploadCompanyLogoFile(file as RcFile);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSave = async () => {
    setLoading(true);
    if (userInfo.phone !== initialPhone) {
      setPhone(userInfo.phone);
      setnumModal(true);
      return;
    }
    const uploadToS3 = async (
      file: RcFile | undefined,
      pathPrefix: string,
      id: string
    ) => {
      if (!file) return null;
      const fileName = file.name || '';
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      const newFileName = `${id}.${fileExtension}`;

      const uploadResult = await uploadData({
        path: `${pathPrefix}/${newFileName}`,
        data: file,
      });

      return uploadResult?.result;
    };

    const uploadProfileImageInS3 = await uploadToS3(
      uploadLogoFile,
      'profile',
      userInfo.id
    );
    const uploadCompanyLogoInS3 = await uploadToS3(
      uploadCompanyLogoFile,
      'logo',
      userInfo.organization_id
    );

    const fullNameParts = userInfo.fullName.trim().split(' ');

    let firstName = '';
    let lastName = '';

    if (fullNameParts.length > 1) {
      lastName = fullNameParts.pop() || '';
      firstName = fullNameParts.join(' ');
    } else {
      firstName = fullNameParts[0] || '';
    }

    const payload: any = {
      user: {
        first_name: firstName,
        last_name: lastName || '',
        title: userInfo.title,
        phone: userInfo.phone,
        profile: uploadProfileImageInS3
          ? uploadProfileImageInS3?.path
          : undefined,
      },
      organization: {
        logo: uploadCompanyLogoInS3 ? uploadCompanyLogoInS3?.path : undefined,
      },
    };

    const path = `user`;
    try {
      const response = await doPatch(path, payload);
      if (response && response.status === 200) {
        message.success('User Profile Updated Successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    const path = `user`;
    try {
      const response = await doGet(path);
      if (response) {
        const {
          first_name,
          last_name,
          email,
          title,
          phone,
          profile,
          id,
          organization_id,
          organization_name,
          organization_domain,
          organization_logo,
        } = response.data;
        setPhone(phone);
        let profileImageUrl;
        try {
          const { url } = await getUrl({ path: profile });
          profileImageUrl = url?.href;
          setProfileImageUrl(profileImageUrl);
        } catch (error) {
          console.error('Error fetching image URL:', error);
        }

        let companyImageUrl = '';
        if (organization_logo) {
          if (organization_logo.startsWith('http')) {
            companyImageUrl = organization_logo;
          } else {
            try {
              const { url } = await getUrl({ path: organization_logo });
              companyImageUrl = url?.href || '';
            } catch (error) {
              console.error('Error fetching organization logo URL:', error);
            }
          }
        } else {
          companyImageUrl = '';
        }
        setCompanyImageUrl(companyImageUrl);

        const fullName =
          `${first_name} ${last_name && last_name !== 'null' ? last_name : ''}`.trim();

        const userInfoData = {
          id,
          fullName,
          title,
          email,
          phone: phone || '',
          profile: profile || '',
          organization_id,
          organization_name,
          organization_domain,
          organization_logo: organization_logo || '',
        };

        setUserInfo(userInfoData);
        setInitialPhone(phone || '');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const onClosePassword = () => {
    setIsPasswordModal(false);
    setNewPassword('');
    setConfirmPassword('');
    setCurrentPassword('');
    setFocusedField(null);
  };
  const handlePasswordOk = async () => {
    if (emailVerified) {
      await handleChangePassword();
    } else {
      await handleAddPassword();
    }
  };

  const handleChangePassword = async () => {
    if (newPasswordError || confirmPasswordError || currentPasswordError) {
      return;
    }
    if (!currentPassword || !newPassword || !confirmPassword) {
      message.error('All fields are required!');
      return;
    }
    if (newPassword !== confirmPassword) {
      message.error('New password and confirm password must match!');
      return;
    }
    try {
      await updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      message.success('Password updated successfully!');
      onClosePassword();
    } catch (error) {
      console.error('Error updating password:', error);

      let errorMessage =
        'An unknown error occurred while updating the password.';

      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'object' && error !== null) {
        const typedError = error as { code?: string; message?: string };
        if (typedError.code) {
          switch (typedError.code) {
            case 'NotAuthorizedException':
              errorMessage = 'Incorrect current password. Please try again.';
              break;
            case 'LimitExceededException':
              errorMessage =
                'You have exceeded the maximum number of password change attempts. Please try again later.';
              break;
            case 'InvalidParameterException':
              errorMessage =
                'The new password is not valid. Please meet the password policy requirements.';
              break;
            default:
              errorMessage = `An error occurred: ${typedError.message || 'Unknown error'}`;
          }
        } else if (typedError.message) {
          errorMessage = typedError.message;
        }
      } else if (typeof error === 'string') {
        errorMessage = error;
      }

      message.error(errorMessage);
    }
  };
  const handleAddPassword = async () => {
    if (newPasswordError || confirmPasswordError) {
      return;
    }

    if (!newPassword || !confirmPassword) {
      message.error('All fields are required!');
      return;
    }

    if (newPassword !== confirmPassword) {
      message.error('New password and confirm password must match!');
      return;
    }
    try {
      message.success('Password added successfully!');
      localStorage.setItem('emailVerified', 'true');
      setEmailVerified(true);
      onClosePassword();
    } catch (error) {
      console.error('Error adding password:', error);
    }
  };

  const validatePasswordFields = () => {
    switch (focusedField) {
      case 'currentPassword':
        if (!currentPassword) {
          setCurrentPasswordError('Current password is required');
        } else {
          setCurrentPasswordError('');
        }
        break;
      case 'newPassword':
        if (!newPassword || newPassword.length < 8) {
          setNewPasswordError('Password must be at least 8 characters long');
        } else {
          setNewPasswordError('');
        }
        break;
      case 'confirmPassword':
        if (!confirmPassword) {
          setConfirmPasswordError('Please confirm your password');
        } else if (confirmPassword !== newPassword) {
          setConfirmPasswordError('Passwords do not match');
        } else {
          setConfirmPasswordError('');
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const storedEmailVerified = localStorage.getItem('emailVerified');
    setEmailVerified(storedEmailVerified === 'true');
  }, [emailVerified]);

  const formatPhoneNumber = (phoneNumber: any): string => {
    try {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    } catch (error) {
      console.error('Invalid phone number:', phoneNumber);
      return phoneNumber;
    }
  };

  const initials = `${userDetails?.userInfo?.first_name?.[0] || ''}${userDetails?.userInfo?.last_name?.[0] || ''}`;

  return (
    <div className="myInfo">
      <Row>
        <Col span={24}>
          <div className="my_info_item">
            <h3>Profile</h3>
            {!loading ? (
              <Row gutter={16}>
                <Col span={12}>
                  <div className="upload_profile">
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'profile')}
                    />

                    <div className="upload_profile_pic">
                      {imageView ? (
                        <img
                          src={imageView}
                          alt="Profile Preview"
                          className="profile_image"
                          loading="lazy"
                        />
                      ) : profileImageUrl ? (
                        <img
                          src={profileImageUrl}
                          alt="Profile Picture"
                          className="profile_image"
                          loading="lazy"
                        />
                      ) : (
                        <span className="name">{initials}</span>
                      )}
                      <div
                        className="upload_edit_icon"
                        onClick={() => handleUploadClick('profile')}
                      >
                        <EditOutlined />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col span={12}>
                  <Skeleton.Node
                    active={true}
                    style={{
                      height: '70px',
                      width: '70px',
                      borderRadius: '50%',
                    }}
                  />
                </Col>
              </Row>
            )}
            {!loading ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Full name</label>
                    <Input
                      type="text"
                      placeholder="Full name"
                      name="my_info_full_name"
                      value={userInfo.fullName}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, fullName: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Title</label>
                    <Input
                      type="text"
                      placeholder="Title"
                      name="my_info_title"
                      value={userInfo.title}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, title: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Email</label>
                    <Input
                      type="email"
                      placeholder="Email"
                      value={userInfo.email}
                      // onChange={(e) =>
                      //   setUserInfo({ ...userInfo, email: e.target.value })
                      // }
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Phone Number</label>
                    <PhoneInput
                      defaultCountry="US"
                      placeholder="Phone Number"
                      value={userInfo.phone}
                      onChange={handlePhoneChange}
                      onCountryChange={(selectedCountry) => {
                        setCountry(selectedCountry as string);
                      }}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Flex justify="flex-end" align="center">
                    <span>
                      {emailVerified ? (
                        <span
                          onClick={() => setIsPasswordModal(true)}
                          className="password_link"
                        >
                          <LockOutlined style={{ marginRight: '10px' }} />
                          Change Password
                        </span>
                      ) : (
                        <span
                          onClick={() => setIsPasswordModal(true)}
                          className="password_link"
                        >
                          <LockOutlined style={{ marginRight: '10px' }} />
                          Create Password
                        </span>
                      )}
                    </span>
                  </Flex>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col span={24}>
                  <Flex justify="flex-end">
                    <Skeleton.Button
                      active
                      size="default"
                      style={{ margin: '15px 0' }}
                    />
                  </Flex>
                </Col>
              </Row>
            )}
            <Divider style={{ background: '#f5f5f5' }} />
            <h3>Compnay Info</h3>
            {!loading ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Company name</label>
                    <Input
                      type="text"
                      placeholder="Company name"
                      name="my_info_company_name"
                      value={userInfo?.organization_name}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="form-item">
                    <label>Company Website</label>
                    <Input
                      type="text"
                      placeholder="Company Website"
                      name="my_info_company_website"
                      value={userInfo?.organization_domain}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="form-item">
                    <label>Your Logo</label>
                    <input
                      type="file"
                      ref={companyFileInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'company')}
                    />

                    <div className="company_logo_wrapper">
                      {companyImageView ? (
                        <img
                          src={companyImageView}
                          alt="Company Logo Preview"
                          className="company_logo"
                        />
                      ) : companyImageUrl ? (
                        <img
                          src={companyImageUrl}
                          alt="Company Logo"
                          className="company_logo"
                        />
                      ) : (
                        <div className="company_logo">
                          {userInfo.organization_name?.[0]?.toUpperCase()}
                        </div>
                      )}
                      <div
                        className="upload_edit_icon"
                        onClick={() => handleUploadClick('company')}
                      >
                        <EditOutlined />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <PrimaryButton text="Save" onClick={handleSave} />
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Skeleton.Button
                    active
                    size="default"
                    block={true}
                    style={{ margin: '15px 0' }}
                  />
                </Col>
                <Col xs={12} md={12}>
                  <Skeleton.Node
                    active={true}
                    style={{
                      height: '70px',
                      width: '70px',
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Flex justify="flex-end">
                    <Skeleton.Button
                      active
                      size="default"
                      style={{ margin: '15px 0' }}
                    />
                  </Flex>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Modal
        open={isPasswordModal}
        title=""
        className="password_change_modal"
        centered
        onOk={handlePasswordOk}
        onCancel={onClosePassword}
        closable={false}
        okText="Save"
      >
        <Row gutter={16}>
          {emailVerified ? (
            <Col span={24}>
              <h3>Change Password</h3>
              <p>
                Changing the password will log you out of all your sessions. You
                will need to log in again.
              </p>
            </Col>
          ) : (
            <Col span={24}>
              <h3>Add Password</h3>
              <p>
                Adding a password will log you out of all sessions. You will
                need to log in again.
              </p>
            </Col>
          )}
        </Row>
        {emailVerified && (
          <Row
            gutter={16}
            style={{ marginBottom: '15px', position: 'relative' }}
          >
            <Col xs={24} lg={16}>
              <div className="form-item">
                <label>Current Password</label>
                <Input.Password
                  placeholder="Enter current password"
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setCurrentPasswordError('');
                  }}
                  onFocus={() => setFocusedField('currentPassword')}
                  onBlur={() => {
                    validatePasswordFields();
                    setFocusedField(null);
                  }}
                />
                <p
                  className={currentPasswordError ? 'error' : 'no-error'}
                  style={{ color: 'red', marginTop: '5px' }}
                >
                  {currentPasswordError}
                </p>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={16} style={{ marginBottom: '15px', position: 'relative' }}>
          <Col xs={24} lg={16}>
            <div className="form-item">
              <label>New Password</label>
              <Input.Password
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordError('');
                }}
                onFocus={() => setFocusedField('newPassword')}
                onBlur={() => {
                  validatePasswordFields();
                  setFocusedField(null);
                }}
              />
              <p
                className={newPasswordError ? 'error' : 'no-error'}
                style={{ color: 'red', marginTop: '5px' }}
              >
                {newPasswordError}
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: '15px', position: 'relative' }}>
          <Col xs={24} lg={16}>
            <div className="form-item">
              <label>Confirm Password</label>
              <Input.Password
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError('');
                }}
                onFocus={() => setFocusedField('confirmPassword')}
                onBlur={() => {
                  validatePasswordFields();
                  setFocusedField(null);
                }}
              />
              <p
                className={confirmPasswordError ? 'error' : 'no-error'}
                style={{ color: 'red', marginTop: '5px' }}
              >
                {confirmPasswordError}
              </p>
            </div>
          </Col>
        </Row>
      </Modal>

      {numModal && (
        <Addnumber
          onClose={() => {
            setnumModal(false);
            setLoading(false);
          }}
          setPhoneadd={setPhoneadd}
          setnumModal={setnumModal}
          setnumber={setnumber}
          user_id={userInfo?.id}
          setPhone={setPhone}
          phone={phone}
        />
      )}

      {phoneadd && (
        <Verify
          onClose={() => {
            setPhoneadd(false);
            setLoading(false);
          }}
          setPhoneadd={setPhoneadd}
          setSuccessModal={setSuccessModal}
          number={number}
          user_id={userInfo?.id}
          formatPhoneNumber={formatPhoneNumber}
          getUsersInfo={fetchUserInfo}
        />
      )}
      {success && <Success onClose={() => setSuccessModal(false)} />}
    </div>
  );
};

export default MyInfo;
