import { Button, Popover, Row, Space } from 'antd';
import CheckboxComponent from '../../../common/CheckboxComponent';
import { CaretDownOutlined } from '@ant-design/icons';
import React from 'react';

const CreatedDateFilter: React.FC = () => {
  const dateOptions = [
    { label: 'Dipal', value: 'Dipal' },
    { label: 'Arthur Smith', value: 'Arthur Smith' },
    { label: 'Swawn Henry', value: 'Swawn Henry' },
  ];

  return (
    <Popover
      overlayClassName="userFilters"
      content={
        <>
          {dateOptions?.map((item, index) => {
            return (
              <Row key={index} className="mt-5 mb-5">
                <CheckboxComponent label={item.label} value={item.value} />
              </Row>
            );
          })}
        </>
      }
      trigger="click"
      placement="bottom"
    >
      <Button type="text" className="p-0  mr-20">
        <Space>
          Created Date
          <CaretDownOutlined />
        </Space>
      </Button>
    </Popover>
  );
};

export default CreatedDateFilter;
