import { Input } from 'antd';
import React from 'react';
import './common.less';

interface Seach {
  placeholder: string;
  onChange?: (value: string) => void;
  value?: string;
}

const SearchComponent: React.FC<Seach> = ({ placeholder, onChange, value }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value); // Extract the value from the event
    }
  };
  const { Search } = Input;
  return (
    <Search
      placeholder={placeholder}
      className="searchbar-style"
      onChange={handleChange}
      value={value}
    />
  );
};

export default SearchComponent;
