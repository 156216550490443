import React from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import FullPageLayout from './FullPageLayout';
interface PublicLayoutRouteProps {
  element: React.ReactNode;
}

const PublicLayoutRoute: React.FC<PublicLayoutRouteProps> = ({
  element,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const token = localStorage.getItem('token');

  return !token ? (
    <FullPageLayout>
      {React.cloneElement(element as React.ReactElement, {
        navigate,
        location,
        params,
        ...rest,
      })}
    </FullPageLayout>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PublicLayoutRoute;
