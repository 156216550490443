import { Button, Grid, Modal, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React, { useState } from 'react';
import PrimaryButton from '../../../common/PrimaryButton';
import CancelSubscriptionFeedback from './CancelSubscriptionFeedback';

interface CancelSubscriptionModalProps {
  cancelPlanModal: boolean;
  setCancelPlanModal: (open: boolean) => void;
  userDetails?: any;
  setUsersDetails?: (details: any) => void;
}
const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  cancelPlanModal,
  setCancelPlanModal,
  userDetails,
  setUsersDetails,
}) => {
  const [subscriptionFeedbackModal, setSubscriptionFeedbackModal] =
    useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <>
      <Modal
        open={cancelPlanModal}
        onCancel={() => setCancelPlanModal(false)}
        footer={null}
      >
        <Row justify="center">
          <img
            src={WinrateImages.SubscriptionIcons.cancelSubscriptionCalendar}
          />
        </Row>
        <Row justify="center" className="mt-20">
          <Typography.Title level={4} className="mb-0 fw-500">
            You will get a reminder before your
          </Typography.Title>
          <Typography.Title className="sub-text fw-500" level={4}>
            subscription renews
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text className={!screens.md ? 'text-center' : ''}>
            There are no changes if you cancel before the expiration date.
          </Typography.Text>
        </Row>
        <Row justify="center" className="mt-20">
          <PrimaryButton
            text="Keep My Subscription"
            className="cancel-sub-btn"
            onClick={() => setCancelPlanModal(false)}
          />
        </Row>
        <Row justify="center" className="mt-10">
          <Button
            type="text"
            className="branding-color"
            onClick={() => {
              setSubscriptionFeedbackModal(true);
              setCancelPlanModal(false);
            }}
          >
            Cancel Subscription
          </Button>
        </Row>
      </Modal>
      <CancelSubscriptionFeedback
        setSubscriptionFeedbackModal={setSubscriptionFeedbackModal}
        subscriptionFeedbackModal={subscriptionFeedbackModal}
        userDetails={userDetails}
        setUsersDetails={setUsersDetails}
      />
    </>
  );
};

export default CancelSubscriptionModal;
