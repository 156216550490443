/* eslint-disable react/prop-types */
import { Modal, Row, Typography, ModalProps } from 'antd';
import Addnum from 'src/assets/notification-icons/num_add_success.svg';
import './notification.less'; // Ensure this file contains the required styles

const { Title, Text } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
}

const Success: React.FC<Inummodal> = ({ onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      footer={null}
      width={600}
      centered
      open={true}
      onCancel={handleCancel}
      className="success-modal" // Custom class for modal styling
    >
      <Row className="success-content">
        {' '}
        {/* Custom content styling */}
        <img src={Addnum} alt="Number added success" height={126} />
        <Row className="success-header">
          <Title level={3}>Phone Number Added Successfully!</Title>
          <Text>
            You’re all set! Expect timely text alerts with your prep notes sent
            directly to your phone.
          </Text>
        </Row>
      </Row>
    </Modal>
  );
};

export default Success;
