import { Empty, Grid, Skeleton } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import { EnrichedAttendees } from './models';
import { useState } from 'react';

const CrmContactLink = ({
  crmContact,
  crmId,
  crmProvider,
}: {
  crmContact: any;
  crmId?: string;
  crmProvider?: string;
}) => {
  if (!crmId || !crmProvider) {
    return null;
  }
  if (crmProvider === 'hubspot') {
    return (
      <div
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          padding: '5px 0px',
        }}
      >
        <img
          src={WinrateImages.Icons.hubspotIcon}
          alt="Hubspot logo"
          width="18px"
        />
        <a
          style={{ color: '#0072b1', fontWeight: '500' }}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubspot.com/contacts/${crmId}/contact/${crmContact['hs_object_id']}`}
        >
          View in Hubspot
        </a>
      </div>
    );
  } else if (crmProvider === 'salesforce') {
    //"crmId": "https://winrate2-dev-ed.develop.my.salesforce.com",
    // get "winrate2-dev-ed" from "crmId"
    let salesforceInstance = crmId.split('://')[1];
    salesforceInstance = salesforceInstance.split('.')[0];

    return (
      <div
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          padding: '5px 0px',
        }}
      >
        <img
          src={WinrateImages.Auth.salesForce}
          alt="Salesforce logo"
          width="18px"
        />
        <a
          style={{ color: '#0072b1', fontWeight: '500' }}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${salesforceInstance}.develop.lightning.force.com/lightning/r/Contact/${crmContact['id']}/view`}
        >
          View in Salesforce
        </a>
      </div>
    );
  }

  return null;
};

export const AttendeeSection = ({
  loading,
  title,
  attendees = [],
  contacts,
  crmId,
  crmProvider,
}: {
  loading: boolean;
  title?: string;
  attendees: EnrichedAttendees[];
  contacts: any;
  crmId?: string;
  crmProvider?: string;
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [showAll, setShowAll] = useState(false);
  const isMobile = !screens.md;
  const filteredAttendees = attendees.filter((a) => !!a.contact.full_name);
  const attendeesToShow = isMobile
    ? filteredAttendees.length <= 2
      ? filteredAttendees
      : showAll
        ? filteredAttendees
        : filteredAttendees.slice(0, filteredAttendees.length - 2)
    : filteredAttendees;

  const handleLoadMore = () => {
    setShowAll(true);
  };
  const remainingCount = filteredAttendees.length - attendeesToShow.length;

  return (
    <div
      className="note-attendees"
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
    >
      {loading ? (
        <Skeleton.Input
          active={true}
          size={'default'}
          block={true}
          style={{ marginBottom: '20px' }}
        />
      ) : (
        <h2 className="section-title">{title}</h2>
      )}
      {!loading && attendees.length === 0 && (
        <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
      )}

      {loading && !attendees && (
        <div
          style={{
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
          }}
        >
          <Skeleton.Node
            active={true}
            style={{ height: '100px', width: '100%' }}
          />
          <Skeleton.Node
            active={true}
            style={{ height: '100px', width: '100%' }}
          />
          <Skeleton.Node
            active={true}
            style={{ height: '100px', width: '100%' }}
          />
        </div>
      )}
      {attendees?.length > 0 && (
        <div className="attendee_wrapper">
          {attendeesToShow.map((attendee, i) => (
            <div key={`attendee-${i}`} className="attendee-card shadow-card">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={attendee.contact.linkedin_url}
              >
                <div className="align-flex">
                  <div className="contact-name">
                    {attendee.contact.full_name}
                  </div>
                  <img
                    src="https://logo.clearbit.com/linkedin.com"
                    width="18"
                  />
                </div>
              </a>
              <div className="contact-info">
                <p>
                  {attendee.contact.occupation}
                  {attendee.contact.headline !==
                    attendee.contact.occupation && (
                    <>
                      <br />
                      <span>{attendee.contact.headline}</span>
                    </>
                  )}
                  {attendee?.contact?.previous_role?.title && (
                    <>
                      .{' '}
                      <span>
                        Previously {attendee?.contact?.previous_role?.title} at{' '}
                        {attendee?.contact?.previous_role?.company}
                      </span>
                    </>
                  )}
                </p>
              </div>
              {contacts?.[attendee.attendee] && (
                <CrmContactLink
                  crmContact={contacts[attendee.attendee]}
                  crmId={crmId}
                  crmProvider={crmProvider}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {isMobile && filteredAttendees.length > 2 && !showAll && (
        <button onClick={handleLoadMore} className="load_more">
          {remainingCount} more
        </button>
      )}
    </div>
  );
};
