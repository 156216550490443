import React, { useEffect, useState } from 'react';
import { Col, Grid, Layout, Row, Typography } from 'antd';
import NavigationMenu from './NavigationMenu';
import HeaderComponent from './Header';
import './layout.less';
import { WinrateImages } from '../assets/Images';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface MainLayoutProps {
  children?: React.ReactNode; // More specific type for children
  collapsed?: boolean;
  setCollapsed?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { Content, Sider, Header } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { winrateLogoCollapse } = WinrateImages.Icons;
  const { loginLogo } = WinrateImages.Auth;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const freeTrialDays = useSelector((state: RootState) => state.auth.trialDays);

  const paymentStatus = useSelector(
    (state: RootState) => state.auth.payment_status
  );

  useEffect(() => {
    if (screens.xs) {
      setCollapsed(true);
    }
  }, [screens, collapsed]);

  return (
    <>
      <Layout className="layout">
        <Sider
          className={collapsed ? 'siderbar collapse-menu' : 'siderbar'}
          width="250px"
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="md"
          collapsedWidth="0"
        >
          {collapsed ? (
            <img
              src={winrateLogoCollapse}
              alt="winrate-log"
              className="collapse-logo"
            />
          ) : (
            <img
              src={loginLogo}
              alt="winrate-log"
              className="logo collapsed_logo"
            />
          )}
          <NavigationMenu collapsed={collapsed} />
        </Sider>
        <Layout>
          <Header>
            <HeaderComponent
              setCollapsed={setCollapsed}
              collapsed={collapsed}
            />
          </Header>
          {!screens.md &&
            window.location.pathname === '/' &&
            paymentStatus === 'free' && (
              <Row justify="center">
                <Col span={24} className="trial-end-text">
                  <img src={WinrateImages.Icons.mobileHourGlassIcon} />
                  <Typography.Text className="trial-text-center fw-500">
                    {' '}
                    Free trial ends in {freeTrialDays} Days
                  </Typography.Text>
                </Col>
              </Row>
            )}
          <Content
            className={
              window.location.pathname === '/team-detail'
                ? 'custom-main'
                : 'main'
            }
            id="main"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
