import { Button, Modal, Row, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';

interface DisconnectProps {
  setDisConnectModal: Dispatch<SetStateAction<boolean>>;
  disConnectModal: boolean;
  selectedIntegration: string;
  disconnectIntegration: (e: any) => Promise<void>;
  disconnectLoader: { [key: string]: boolean };
  integrationStatus: any;
}

const DisconnectModal: React.FC<DisconnectProps> = ({
  setDisConnectModal,
  disConnectModal,
  selectedIntegration,
  disconnectIntegration,
  disconnectLoader,
  integrationStatus,
}) => {
  const handleDisconnectSlack = async () => {
    try {
      window.open(integrationStatus.slackDisconnectUrl, '_blank');
      setDisConnectModal(false);
    } catch (err) {
      console.error('Error');
    }
  };

  return (
    <Modal
      centered
      width={550}
      closable={false}
      open={disConnectModal}
      footer={
        <>
          <SecondaryButton
            text="Cancel"
            onClick={() => setDisConnectModal(false)}
          />
          {selectedIntegration === 'slack' ? (
            <Button
              onClick={() => {
                handleDisconnectSlack();
              }}
              loading={disconnectLoader[selectedIntegration]}
              className="delete-account-btn"
            >
              Disconnect Slack
            </Button>
          ) : (
            <PrimaryButton
              text={`Disconnect ${selectedIntegration}`}
              onClick={() => {
                disconnectIntegration(selectedIntegration);
              }}
              loading={disconnectLoader[selectedIntegration]}
            />
          )}
        </>
      }
    >
      {selectedIntegration === 'slack' ? (
        <>
          <Typography.Title level={4} className="fw-400">
            Are you sure you want to disconnect?
          </Typography.Title>

          <Row>
            <Typography.Text className="warning-text fw-500 fs-16">
              Removing Slack integration will disconnect it for everyone in your
              organization.
            </Typography.Text>
          </Row>
          <Row className="mt-10">
            <Typography.Text>
              Team members relying on Slack-linked features may experience
              interruptions.
            </Typography.Text>
          </Row>
        </>
      ) : (
        <>
          <Typography.Title level={4} className="fw-400">
            Are you sure you want to disconnect?
          </Typography.Title>
          <Typography.Text className="mt-20">
            Disconnecting will stop fetching data from your CRM{' '}
            <span className="text-capitalize">{selectedIntegration}</span>
          </Typography.Text>
        </>
      )}
    </Modal>
  );
};

export default DisconnectModal;
