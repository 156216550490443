/* eslint-disable react/prop-types */
import {
  Col,
  Modal,
  Row,
  Typography,
  Button,
  ModalProps,
  Form,
  message,
  Grid,
} from 'antd';
import { useEffect, useState } from 'react';
import { doPost } from '../../../sdk/Events';
import './notification.less';
import 'react-phone-number-input/style.css'; // Import the styles
import { isValidPhoneNumber } from 'libphonenumber-js'; // Optional for validation

import PhoneInput from 'react-phone-number-input';
import PrimaryButton from '../../../common/PrimaryButton';

const { Title, Text } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setPhoneadd: (value: boolean) => void;
  setnumModal: (value: boolean) => void;
  setnumber: (value: string) => void;
  user_id: string;
  setPhone: (value: string) => void;
  phone: any;
}

const Addnumber: React.FC<Inummodal> = ({
  onClose,
  setPhoneadd,
  setnumModal,
  setnumber,
  user_id,
  setPhone,
  phone,
}) => {
  const [form] = Form.useForm();
  const [country, setCountry] = useState<string>('US');
  const [loading, setLoading] = useState(false);
  console.log(country);
  const handleCancel = () => {
    onClose();
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handlePhoneChange = (value: string | undefined) => {
    setPhone(value as string);
  };
  const verifyPhoneNumber = async () => {
    setLoading(true);
    const phoneNumber = form.getFieldValue('number');
    if (phoneNumber) {
      if (isValidPhoneNumber(phoneNumber)) {
        try {
          const inputData = {
            phone: `${phoneNumber}`,
            user_id: user_id,
          };
          setnumber(phoneNumber);
          const path = `send-otp`;
          await doPost(path, inputData);
          setPhoneadd(true);
          setnumModal(false);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      } else {
        setLoading(false);
        message.error('Please enter a valid phone number');
      }
    } else {
      message.error('Phone number is required');
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      number: phone,
    });
  }, [form]);

  return (
    <Modal
      footer={null}
      width={600}
      centered
      open={true}
      onCancel={handleCancel}
      className="addnumber-modal" // Custom class for modal styling
    >
      <Row className="addnumber-content">
        <Row
          className={
            !screens.md ? 'addnumber-header w-100' : 'addnumber-header'
          }
        >
          <Title level={4}>Add Your Number for SMS</Title>
          <Text>
            Enter your mobile number to receive timely SMS reminders and updates
            before each meeting.
          </Text>
          <Col>
            <Form form={form} layout="vertical" onFinish={verifyPhoneNumber}>
              <Form.Item
                label="Mobile Number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your mobile number!',
                  },
                ]}
              >
                <PhoneInput
                  defaultCountry="US" // Set default country code here
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter phone number"
                  onCountryChange={(selectedCountry) => {
                    setCountry(selectedCountry as string); // Update country when the user selects it
                  }}
                />
              </Form.Item>
              <Col className="addnumber-actions">
                <PrimaryButton
                  text="Verify"
                  className="w-100"
                  loading={loading}
                  disabled={loading}
                />

                <Button
                  type="text"
                  className="branding-color"
                  onClick={() => setnumModal(false)}
                >
                  I&apos;ll do it later {/* Escaped apostrophe */}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default Addnumber;
