import { Button, Popover, Row, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import React from 'react';
import CheckboxComponent from '../../common/CheckboxComponent';

const StatusFilter: React.FC = () => {
  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Invited', value: 'Invited' },
    { label: 'Deactivated', value: 'Deactivated' },
  ];
  const handleCheckboxChange = (value: string, checked: boolean) => {
    console.log(value, checked);
  };

  return (
    <Popover
      overlayClassName="userFilters"
      content={
        <>
          {statusOptions?.map((item, index) => {
            return (
              <Row key={index} className="mt-5 mb-5">
                <CheckboxComponent
                  label={item.label}
                  value={item.value}
                  onChange={handleCheckboxChange}
                />
              </Row>
            );
          })}
        </>
      }
      trigger="click"
      placement="bottom"
    >
      <Button type="text" className="p-0  mr-20">
        <Space>
          Status
          <CaretDownOutlined />
        </Space>
      </Button>
    </Popover>
  );
};

export default StatusFilter;
