import { createSlice } from '@reduxjs/toolkit';

export interface customResearchState {
  isResearchAdded: boolean;
  isFirstResearch: boolean;
}

const initialState: customResearchState = {
  isResearchAdded: false,
  isFirstResearch: false,
};

export const customResearchSlice = createSlice({
  name: 'customResearchSlice',
  initialState,
  reducers: {
    setisResearchAdded: (state, { payload }) => {
      state.isResearchAdded = payload;
    },
    setisFirstResearch: (state, { payload }) => {
      state.isFirstResearch = payload;
    },
  },
});

export const { setisResearchAdded, setisFirstResearch } =
  customResearchSlice.actions;
export default customResearchSlice.reducer;
