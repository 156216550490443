import { Button, Col, Modal, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React, { useState } from 'react';
import SecondaryButton from '../../../common/SecondaryButton';
import DeleteAccountFeedbackModal from './DeleteAccountFeedbackModal';
import CancelSubscriptionModal from './CancelSubscriptionModal';

interface DeleteModalProps {
  setDeleteModalOpen: (open: boolean) => void;
  deleteModalOpen: boolean;
  userDetails: any;
}
const DeleteAccountModal: React.FC<DeleteModalProps> = ({
  deleteModalOpen,
  setDeleteModalOpen,
  userDetails,
}) => {
  const [deleteAccountFeedbackModalOpen, setDeleteAccountFeedbackModalOpen] =
    useState(false);
  const [cancelPlanModal, setCancelPlanModal] = useState(false);

  return (
    <>
      <Modal
        width={600}
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        footer={null}
        className="DeleteaccountModal"
      >
        <Row justify="center">
          <img src={WinrateImages.SubscriptionIcons.deleteAccountIcon} />
        </Row>
        <Row justify="center" className="mt-10">
          <Typography.Title level={4} className="fw-500">
            Delete Account
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text className="text-secondary text-center">
            We recommend that you keep your account. If you still wish to
            proceed, you can choose to cancel your subscription
          </Typography.Text>
        </Row>

        <Row className="mt-20" justify="center">
          <Col md={2} sm={3} xs={3} xl={2} lg={2}>
            <img src={WinrateImages.SubscriptionIcons.redCalendarIcon} />
          </Col>
          <Col md={22} sm={21} xs={21} xl={22} lg={22}>
            <Typography.Text>
              You have an active subscription. If you delete your account, you
              will lose all data and connected accounts.
            </Typography.Text>
          </Col>
        </Row>

        <Row justify="center">
          <SecondaryButton
            text="Cancel Subscription"
            className="cancel-sub-btn mt-20"
            onClick={() => {
              setCancelPlanModal(true);
              setDeleteModalOpen(false);
            }}
            disabled={
              userDetails?.subscription_status === 'paid' ? false : true
            }
          />
        </Row>

        <Row justify="center">
          <Button
            type="text"
            className="branding-color mt-10"
            onClick={() => {
              setDeleteAccountFeedbackModalOpen(true);
              setDeleteModalOpen(false);
            }}
          >
            Proceed to Delete Account
          </Button>
        </Row>
      </Modal>
      <DeleteAccountFeedbackModal
        setDeleteAccountFeedbackModalOpen={setDeleteAccountFeedbackModalOpen}
        deleteAccountFeedbackModalOpen={deleteAccountFeedbackModalOpen}
        userDetails={userDetails}
      />
      <CancelSubscriptionModal
        cancelPlanModal={cancelPlanModal}
        setCancelPlanModal={setCancelPlanModal}
        userDetails={userDetails}
      />
    </>
  );
};

export default DeleteAccountModal;
