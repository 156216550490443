import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Divider, Skeleton, Collapse, Empty } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const Research = ({
  loading,
  title,
  researchItems,
}: {
  loading: boolean;
  title?: string;
  researchItems: any[];
}) => {
  return (
    <div className="note-research">
      {loading && !researchItems.length ? (
        <Skeleton active={true} />
      ) : (
        <Collapse defaultActiveKey={['1']} className="research_collapse">
          <Panel header={title} key="1">
            {!loading && researchItems.length === 0 && (
              <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
            )}
            {researchItems.length > 0 ? (
              <div className="note-research-notes">
                {researchItems.map((research, i) => (
                  <div key={`research-${i}`}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                      {research.message}
                    </Markdown>

                    {/* Collapse for Sources */}
                    {research.citations && research.citations.length > 0 && (
                      <Collapse className="source_collapse">
                        <Panel header="Sources" key={`sources-${i}`}>
                          <ul>
                            {research.citations.map(
                              (citation: string, j: number) => (
                                <li key={`citation-${j}`}>
                                  <MinusOutlined />
                                  <a
                                    href={citation}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {citation}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </Panel>
                      </Collapse>
                    )}
                    {researchItems.length - 1 > i && <Divider />}
                  </div>
                ))}
              </div>
            ) : (
              !loading && ''
            )}
          </Panel>
        </Collapse>
      )}
    </div>
  );
};
