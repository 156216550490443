import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Welcome from './Welcome';
import Research from './Research';
import { doGet } from '../../../sdk/Events';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDispatch } from 'react-redux';
import { setisResearchAdded } from '../../../store/customResearchSlice';
import { ResearchItem } from './CustomResearchTypes';

const CustomResearch: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [customResearchs, setCustomResearchs] = useState<ResearchItem[]>([]);
  const isResearchAdded = useSelector(
    (state: RootState) => state.customResearch.isResearchAdded
  );
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const dispatch = useDispatch();
  const fetchCustomResearchs = async () => {
    setLoading(true);
    const path = `setting/custom_research/${userDetails?.userInfo?.id}`;
    try {
      const response = await doGet(path);
      if (response) {
        setCustomResearchs(response?.data);
      } else {
        setCustomResearchs([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setCustomResearchs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails?.userInfo?.id) {
      fetchCustomResearchs();
    }
    if (isResearchAdded) {
      dispatch(setisResearchAdded(false));
    }
  }, [userDetails?.userInfo?.id, isResearchAdded]);

  return (
    <div className="customResearch">
      <Row>
        <Col span={24}>
          <div className="custom_research_item">
            {loading || (customResearchs?.length == 0 && <Welcome />)}
            <Research customResearchs={customResearchs} loading={loading} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomResearch;
