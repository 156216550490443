import { Row } from 'antd';
import React from 'react';
import SearchUsers from '../SearchUsers';
import UserListingTable from '../UserListingTable';
import EditColumns from '../EditColumns';

const UsersListView: React.FC = () => {
  return (
    <>
      <Row className="edit-search-row" justify="space-between" align="middle">
        <SearchUsers />
        <EditColumns />
      </Row>
      <UserListingTable />
    </>
  );
};

export default UsersListView;
