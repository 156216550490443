import {
  Button,
  Col,
  Divider,
  Grid,
  message,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import React, { useState, useEffect } from 'react';
import slackLogo from '../../../assets/icons/Slack.svg';
import hubspotLogo from '../../../assets/icons/hubspot.svg';
import calendarLogo from '../../../assets/icons/Calendar.svg';
import checkLogo from '../../../assets/icons/check.svg';
import salesforceLogo from '../../../assets/icons/Salesforce.svg';
import { doDelete } from '../../../sdk/Events';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import {
  connectCalendar,
  connectHubspot,
  connectSalesForce,
  connectSlack,
} from '../../../utils/IntegrationHelper';
import SlackConnectModal from './SlackConnectModal';
import HubspotConnectModal from './HubspotConnectModal';
import DisconnectModal from './DisconnectModal';
import SalesforceConnectModal from './SalesforceConnectModal';

interface IntegrationProps {
  loading: boolean;
  integrationStatus: any;
  user_id: string;
  getIntegrationStatus: () => void;
}

const Integrations: React.FC<IntegrationProps> = ({
  loading,
  integrationStatus,
  user_id,
  getIntegrationStatus,
}) => {
  const [disconnectLoader, setDisconnectLoader] = useState<{
    [key: string]: boolean;
  }>({});

  const [slackModalOpen, setSlackModalOpen] = useState(false);
  const [hubspotModalOpen, setHubspotModalOpen] = useState(false);
  const [salesforceModalOpen, setSaleforceModalOpen] = useState(false);
  const [disConnectModal, setDisConnectModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState('');

  useEffect(() => {
    const fromAuthCallback = new URLSearchParams(window.location.search);
    const auth = fromAuthCallback.get('auth');
    const error = fromAuthCallback.get('message');
    if (auth === 'failed') {
      message.error(error);
    }
  }, []);

  const disconnectIntegration = async (e: any) => {
    setDisconnectLoader((prev) => ({ ...prev, [e]: true }));
    try {
      const path = `setting/${e}/${user_id}`;
      await doDelete(path);
      setTimeout(async () => {
        await getIntegrationStatus();
        message.success('Disconnected successfully');
        setDisconnectLoader((prev) => ({ ...prev, [e]: false }));
        setDisConnectModal(false);
      }, 1000);
    } catch (error: any) {
      setDisconnectLoader((prev) => ({ ...prev, [e]: false }));
      message.error(error.message);
    }
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <div>
      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={slackLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={slackLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              Slack
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Get instant notifications about important updates and meeting
              reminders directly in Slack.{' '}
            </Typography.Text>
            {integrationStatus.slack !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setSlackModalOpen(true)}
              >
                Why Connect Slack?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.slack === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              className={
                screens.xs
                  ? 'connect-btn settings_action_btn disconnect'
                  : 'settings_action_btn disconnect'
              }
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('slack');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              onClick={() => connectSlack(user_id, 'notification')}
              text="Connect"
              className={
                screens.xs
                  ? 'connect-btn settings_action_btn'
                  : 'settings_action_btn'
              }
              disabled={loading}
            />
          </Col>
        )}
      </Row>

      <Divider className="divider-margin" />

      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={hubspotLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={hubspotLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              HubSpot
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Sync up-to-date lead activities and engagement history to enrich
              your prep notes.
            </Typography.Text>
            {integrationStatus.hubspot !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setHubspotModalOpen(true)}
              >
                Why Connect Hubspot?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.hubspot === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              className="settings_action_btn disconnect"
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('hubspot');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              text="Connect"
              className="settings_action_btn"
              onClick={() => connectHubspot(user_id, 'notification')}
              disabled={loading}
            />
          </Col>
        )}
      </Row>

      <Divider className="divider-margin" />
      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={calendarLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={calendarLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              Google Calendar
            </Typography.Text>
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.google === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Button type="text" className="p-0">
              <img src={checkLogo} alt="Checked" />
              <span>Connected</span>
            </Button>
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              onClick={() => connectCalendar(user_id)}
              text="Connect"
              className="settings_action_btn"
              disabled={loading}
            />
          </Col>
        )}
      </Row>
      <Divider className="divider-margin" />
      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={salesforceLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={salesforceLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              Salesforce
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Connecting Salesforce lets you bring in valuable CRM data for your
              meeting prep.
            </Typography.Text>
            {integrationStatus.salesforce !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setSaleforceModalOpen(true)}
              >
                Why Connect Salesforce?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.salesforce === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              className="settings_action_btn disconnect"
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('salesforce');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              text="Connect"
              className="settings_action_btn"
              onClick={() => connectSalesForce(user_id, 'notification')}
              disabled={loading}
            />
          </Col>
        )}
      </Row>
      <SlackConnectModal
        setSlackModalOpen={setSlackModalOpen}
        slackModalOpen={slackModalOpen}
        user_id={user_id}
        integrationStatus={integrationStatus}
        disconnectIntegration={disconnectIntegration}
      />
      <HubspotConnectModal
        setHubspotModalOpen={setHubspotModalOpen}
        hubspotModalOpen={hubspotModalOpen}
        user_id={user_id}
      />
      <SalesforceConnectModal
        setSaleforceModalOpen={setSaleforceModalOpen}
        salesforceModalOpen={salesforceModalOpen}
        user_id={user_id}
      />
      <DisconnectModal
        setDisConnectModal={setDisConnectModal}
        disConnectModal={disConnectModal}
        selectedIntegration={selectedIntegration}
        disconnectIntegration={disconnectIntegration}
        disconnectLoader={disconnectLoader}
        integrationStatus={integrationStatus}
      />
    </div>
  );
};

export default Integrations;
