import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Image,
  Row,
  Segmented,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined, CaretDownOutlined, EditFilled } from '@ant-design/icons';
import { WinrateImages } from '../../../assets/Images';
import EditTeamModal from './EditTeamModal';
import DeleteTeamModal from './DeleteTeamModal';
import TitleFilter from '../TitleFilter';
import DepartmentFilter from '../DepartmentFilter';
import AccessGroupFilter from '../AccessGroupFilter';
import StatusFilter from '../StatusFilter';
import PrimaryButton from '../../../common/PrimaryButton';
import UsersListView from './UsersListView';
import UsersGridView from './UsersGridView';

const TeamDetails: React.FC = () => {
  const {
    salesNinja,
    profileIcon,
    greenCircle,
    partyPopper,
    noEntry,
    fire,
    snowFlake,
    gridIcon,
    listIcon,
  } = WinrateImages.TeamIcons;
  const [coverImage, setCoverImage] = useState<string | undefined>(undefined);
  const [editTeamModal, setEditTeamModal] = useState(false);
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState<string | number>(
    'List'
  );

  const props: UploadProps = {
    name: 'file',
    accept: 'image/*',
    multiple: true,
    onChange(info) {
      const file = info.fileList[0].originFileObj;
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setCoverImage(e.target.result as string);
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  };

  const items = [
    {
      label: 'Edit Team Info',
      key: '1',
      onClick: () => setEditTeamModal(true),
    },
    {
      label: 'Share',
      key: '2',
    },
    {
      label: 'Delete Team',
      key: '3',
      onClick: () => setDeleteTeamModal(true),
    },
  ];

  const renderComponent = () => {
    switch (selectedSegment) {
      case 'Grid':
        return <UsersGridView />;
      case 'List':
        return <UsersListView />;
      default:
        return null;
    }
  };

  return (
    <div className="team-detail">
      <div className="teamdetails">
        <img
          src={coverImage ? coverImage : salesNinja}
          alt="Cover"
          className="image-cover"
        />
        <Breadcrumb className="header-breadcrumb">
          <Breadcrumb.Item className="primary-item">
            <Link to="/user-management">
              <LeftOutlined className="mr-10" />
              Teams
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="secondary-item">Details</Breadcrumb.Item>
        </Breadcrumb>
        <Upload {...props} className="upload-img">
          <Button>Edit Cover</Button>
        </Upload>
      </div>
      <Row className="profile-row">
        <Col style={{ position: 'relative' }} span={1}>
          <Image src={profileIcon} className="modal-title avtar-icon" />
          <EditFilled className="edit-icon" />
        </Col>
        <Col span={23} className="mt-10">
          <Row justify="space-between">
            <Col>
              <Typography.Title className="team-name" level={3}>
                Sales Ninja
              </Typography.Title>
            </Col>
            <Col>
              <Dropdown
                menu={{ items }}
                className="action-button"
                trigger={['click']}
              >
                <Button>
                  <Space>
                    Actions
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="detail-wrapper">
        <Row
          justify="space-between"
          className="usercountblock"
          wrap={false}
          gutter={[16, 16]}
        >
          <Col flex={1} className="block" style={{ flex: 1 }}>
            <Typography.Text className="block-text">
              Active Deals
            </Typography.Text>
            <Row justify="space-between" className="text-margin">
              <Typography.Title level={4} className="mb-0 fw-700">
                $60.5k <span className="fs-16 fw-500">(20)</span>
              </Typography.Title>

              <img src={greenCircle} alt="" />
            </Row>
          </Col>
          <Col flex={1} className="block" style={{ flex: 1 }}>
            <Typography.Text className="block-text">Closed Won</Typography.Text>
            <Row justify="space-between" className="text-margin">
              <Typography.Title level={4} className="mb-0">
                $93.2k <span className="fs-16 fw-500">(20)</span>
              </Typography.Title>
              <img src={partyPopper} alt="" />
            </Row>
          </Col>
          <Col flex={1} className="block" style={{ flex: 1 }}>
            <Typography.Text className="block-text">
              {' '}
              Closed Lost
            </Typography.Text>
            <Row justify="space-between" className="text-margin">
              <Typography.Title level={4} className="mb-0">
                $25.6k <span className="fs-16 fw-500">(06)</span>
              </Typography.Title>
              <img src={noEntry} alt="" />
            </Row>
          </Col>
          <Col flex={1} className="block" style={{ flex: 1 }}>
            <Typography.Text className="block-text">
              Hot Deal Rooms
            </Typography.Text>
            <Row justify="space-between" className="text-margin">
              <Typography.Title level={4} className="mb-0">
                4
              </Typography.Title>
              <img src={fire} alt="" />
            </Row>
          </Col>
          <Col flex={1} className="block" style={{ flex: 1 }}>
            <Typography.Text className="block-text">
              Cold Deal Rooms
            </Typography.Text>
            <Row justify="space-between" className="text-margin">
              <Typography.Title level={4} className="mb-0">
                2
              </Typography.Title>
              <img src={snowFlake} alt="" />
            </Row>
          </Col>
        </Row>
        <EditTeamModal
          editTeamModal={editTeamModal}
          setEditTeamModal={setEditTeamModal}
        />
        <DeleteTeamModal
          setDeleteTeamModal={setDeleteTeamModal}
          deleteTeamModal={deleteTeamModal}
        />
        <Row className="mt-24" justify="space-between" align="middle">
          <div>
            <Segmented
              className="mr-20 custom-segmented"
              options={[
                { value: 'List', icon: <img src={listIcon} alt="" /> },
                { value: 'Grid', icon: <img src={gridIcon} alt="" /> },
              ]}
              value={selectedSegment}
              onChange={(value) => setSelectedSegment(value)}
            />
            <TitleFilter />
            <DepartmentFilter />
            <AccessGroupFilter />
            <StatusFilter />
          </div>

          <div>
            <Button type="text" className="branding-color">
              Remove Member
            </Button>
            <PrimaryButton text="Add Member" />
          </div>
        </Row>
        {renderComponent()}
      </div>
    </div>
  );
};

export default TeamDetails;
