import { Checkbox } from 'antd';
import React from 'react';

// type CheckboxChangeEvent = Parameters<typeof Checkbox>[0]['onChange'];

interface CheckboxProp {
  label: string;
  checked?: boolean;
  onChange?: (value: string, checked: boolean) => void;
  value: string;
}
const CheckboxComponent: React.FC<CheckboxProp> = ({
  label,
  onChange,
  value,
}) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange('value', e.target.checked);
    }
  };
  return (
    <Checkbox className="checkbox" onChange={handleChange} value={value}>
      {label}
    </Checkbox>
  );
};

export default CheckboxComponent;
