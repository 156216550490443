import { Button, Modal, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { doPost, setAccessDeniedCallback } from '../../../sdk/Events';
import { WinrateImages } from '../../../assets/Images';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

const SubscriptionExpiredModal = ({
  setShowEndSubscriptionModal,
  showEndSubscriptionModal,
  users,
}: {
  setShowEndSubscriptionModal: (open: boolean) => void;
  showEndSubscriptionModal: boolean;
  users?: any;
}) => {
  useEffect(() => {
    setAccessDeniedCallback(() => setShowEndSubscriptionModal(true));
    return () => setAccessDeniedCallback(null);
  }, []);

  const [renewLoader, setRenewLoader] = useState(false);
  const paymentStatus = useSelector(
    (state: RootState) => state.auth.payment_status
  );
  const renewSubscription = async () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    setRenewLoader(true);
    try {
      const inputData = {
        success_url: `${baseURL}/my-account/subscription`,
        cancel_url: `${baseURL}/my-account/subscription`,
        user_id: users,
      };
      const response = await doPost('stripe-checkout', inputData);
      window.location.href = response.data;
      setRenewLoader(false);
    } catch (err) {
      setRenewLoader(false);
      console.error('Error fetching purchase plan: ', err);
    }
  };

  return (
    <Modal
      open={showEndSubscriptionModal}
      footer={null}
      width={600}
      onCancel={() => setShowEndSubscriptionModal(false)}
    >
      <Row justify="center">
        <img src={WinrateImages.SubscriptionIcons.expiredSubscriptionIcon} />
      </Row>
      <Row justify="center" className="mt-20">
        {paymentStatus === 'expired' ? (
          <Typography.Title level={3} className="success-modal-text fw-500">
            Subscription Expired, <span className="wrapper-text">Renew</span>{' '}
            for Full Access
          </Typography.Title>
        ) : (
          <Typography.Title level={3} className="success-modal-text fw-500">
            Your <span className="wrapper-text">Free</span> Trial Has Ended
          </Typography.Title>
        )}
      </Row>
      <Row justify="center">
        {paymentStatus === 'expired' ? (
          <>
            <Typography.Text>
              Don&apos;t miss out on premium features! Renew your subscription
              to
            </Typography.Text>
            <Typography.Text>
              keep access to everything you love.
            </Typography.Text>
          </>
        ) : (
          <>
            <Typography.Text>
              Your access to premium features has expired. Upgrade now to
            </Typography.Text>
            <Typography.Text>keep enjoying premium features.</Typography.Text>
          </>
        )}
      </Row>
      <Row justify="center" className="mt-10">
        <Button
          className="purchase-btn"
          onClick={renewSubscription}
          loading={renewLoader}
          disabled={renewLoader}
        >
          {paymentStatus === 'expired'
            ? 'Renew Subscription'
            : 'Upgrade to Premium'}
        </Button>
      </Row>
    </Modal>
  );
};

export default SubscriptionExpiredModal;
