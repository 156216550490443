import { Row, Table } from 'antd';
import SearchComponent from '../../../common/SearchComponent';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const GroupTable: React.FC = () => {
  const navigate = useNavigate();
  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Group Name',
      sorter: true,
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: 'User Count',
      sorter: true,
    },
  ];

  const dataSource = [
    {
      key: '1',
      name: 'Admin',
      description: 'Responsible for managing administrative tasks.',
      count: '1',
    },
    {
      key: '2',
      name: 'Manager',
      description: 'Directly manages a sales team.',
      count: '5',
    },
    {
      key: '3',
      name: 'User',
      description: (
        <span>
          The primary contact for specific deals. A cross-functional role that
          involves team members from various departments.
        </span>
      ),

      count: '32',
    },
  ];

  const handleRowClick = (record: object) => {
    navigate('/accessgroup-detail', { state: { data: record } });
  };

  return (
    <>
      <Row className="edit-search-row" justify="space-between" align="middle">
        <SearchComponent placeholder="Search name or email address " />
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </>
  );
};

export default GroupTable;
