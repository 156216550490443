import { Col, Divider, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { doGet } from '../../../sdk/Events';
import ManageAccount from './ManageAccount';
import PaymentDetail from './PaymentDetail';
import PurchasePlan from './PurchasePlan';

type UsersArray = {
  subscription_status: string;
  user_id: string;
  email: string;
};
const Subscription: React.FC = () => {
  const [userDetails, setUsersDetails] = useState({} as UsersArray);
  const [detailsLoading, setDetailsLoading] = useState(false);

  const getUserPaymentStatus = async () => {
    setDetailsLoading(true);
    try {
      const response = await doGet('user');
      setUsersDetails(response.data);
      setDetailsLoading(false);
    } catch (error) {
      setDetailsLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getUserPaymentStatus();
  }, []);
  return (
    <>
      {detailsLoading ? (
        <div>
          <div className="skeletion-style">
            <div>
              <Row className="mt-20">
                <Skeleton.Button active={true} style={{ width: 200 }} />
              </Row>
              <Row className="mt-20">
                <Skeleton paragraph={{ rows: 2 }} style={{ width: 400 }} />
              </Row>
            </div>
            <div className="mt-10">
              <Skeleton.Button active={true} style={{ width: 160 }} />
            </div>
          </div>
          <Row justify="space-between" className="mt-10">
            <Col md={14} sm={24} xs={24} lg={14} className="skeleton-extra">
              <Skeleton.Button active={true} style={{ width: 160 }} />
              <Skeleton.Image active={true} className="skeleton-image mt-10" />
            </Col>
            <Col md={10} sm={24} xs={24} lg={10}>
              <Skeleton active paragraph={{ rows: 6 }} />
            </Col>
          </Row>
        </div>
      ) : userDetails?.subscription_status === 'free' ||
        userDetails?.subscription_status === 'expired' ||
        userDetails?.subscription_status === 'ended' ? (
        <PurchasePlan userDetails={userDetails} />
      ) : (
        (userDetails?.subscription_status == 'paid' ||
          userDetails?.subscription_status == 'canceled') && (
          <PaymentDetail
            userDetails={userDetails}
            setUsersDetails={setUsersDetails}
          />
        )
      )}

      <Divider className="subscription-divider" />
      <ManageAccount
        userDetails={userDetails}
        setUsersDetails={setUsersDetails}
      />
    </>
  );
};

export default Subscription;
