import {
  Button,
  Checkbox,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { useState } from 'react';
import { doPost } from '../../../sdk/Events';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

interface DeleteAccountFeedbackModalProps {
  setDeleteAccountFeedbackModalOpen: (open: boolean) => void;
  deleteAccountFeedbackModalOpen: boolean;
  userDetails: any;
}
const DeleteAccountFeedbackModal: React.FC<DeleteAccountFeedbackModalProps> = ({
  setDeleteAccountFeedbackModalOpen,
  deleteAccountFeedbackModalOpen,
  userDetails,
}) => {
  const navigate = useNavigate();
  const { TextArea } = Input;

  const [usersFeedback, setUsersFeedback] = useState<
    { type: string; text: string }[]
  >([]);
  const [textAreaValues, setTextAreaValues] = useState<{
    [key: string]: string;
  }>({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (checkedValues: string[]) => {
    setSelectedCheckboxes(checkedValues);
    const updatedFeedback = checkedValues.map((value) => ({
      type: value,
      text: textAreaValues[value] || '',
    }));

    setUsersFeedback(updatedFeedback);
  };

  const handleTextAreaChange = (key: string, text: string) => {
    setTextAreaValues((prev) => ({ ...prev, [key]: text }));
    setUsersFeedback((prev) =>
      prev.map((item) => (item.type === key ? { ...item, text } : item))
    );
  };

  const deleteAccount = async () => {
    if (usersFeedback?.length === 0) {
      message.error('Please provide feedback to delete your account');
      return;
    }

    const emptyTextbox = usersFeedback.some(
      (feedback) => feedback.text.trim() === ''
    );

    if (emptyTextbox) {
      message.error(
        'Please provide additional details for selected feedback items.'
      );
      return;
    }

    setLoading(true);
    try {
      const inputData = {
        email: userDetails?.email,
        reason: {
          feedback: usersFeedback,
        },
        user_id: userDetails?.id,
      };
      await doPost('user/delete', inputData);
      message.success('Your account has been deleted successfully');
      signOut({
        global: false,
      })
        .then(() => {
          localStorage.clear();
          navigate('/login');
        })
        .catch((err: any) => {
          message.error(err.message);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      open={deleteAccountFeedbackModalOpen}
      onCancel={() => {
        setDeleteAccountFeedbackModalOpen(false);
        setUsersFeedback([]);
        setTextAreaValues({});
        setSelectedCheckboxes([]);
      }}
      closable={false}
      footer={
        <>
          <SecondaryButton
            text="Cancel"
            onClick={() => {
              setDeleteAccountFeedbackModalOpen(false);
              setUsersFeedback([]);
              setTextAreaValues({});
              setSelectedCheckboxes([]);
            }}
          />
          <Button
            className="delete-account-btn"
            onClick={deleteAccount}
            loading={loading}
            disabled={loading}
          >
            Delete Account
          </Button>
        </>
      }
    >
      <Row>
        <Typography.Title level={4} className="fw-500">
          Before you go, help us improve
        </Typography.Title>
      </Row>
      <Checkbox.Group
        className="feedback-checkbox"
        onChange={(values) => handleCheckboxChange(values as string[])}
        value={selectedCheckboxes}
      >
        <Checkbox className="mt-10" value="not_finding_the_features">
          Not finding the features I need{' '}
        </Checkbox>
        {usersFeedback.some(
          (item) => item.type === 'not_finding_the_features'
        ) && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['not_finding_the_features'] || ''}
            onChange={(e) =>
              handleTextAreaChange('not_finding_the_features', e.target.value)
            }
          />
        )}
        <Checkbox className="mt-10" value="privacy_or_security">
          Concerns about privacy or security
        </Checkbox>
        {usersFeedback.some((item) => item.type === 'privacy_or_security') && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['privacy_or_security'] || ''}
            onChange={(e) =>
              handleTextAreaChange('privacy_or_security', e.target.value)
            }
          />
        )}
        <Checkbox className="mt-10" value="wrong_information">
          Wrong information
        </Checkbox>
        {usersFeedback.some((item) => item.type === 'wrong_information') && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['wrong_information'] || ''}
            onChange={(e) =>
              handleTextAreaChange('wrong_information', e.target.value)
            }
          />
        )}
        <Checkbox className="mt-10" value="too_expensive">
          Too expensive
        </Checkbox>
        {usersFeedback.some((item) => item.type === 'too_expensive') && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['too_expensive'] || ''}
            onChange={(e) =>
              handleTextAreaChange('too_expensive', e.target.value)
            }
          />
        )}
        <Checkbox className="mt-10" value="notifications_or_emails">
          Too many notifications or emails
        </Checkbox>
        {usersFeedback.some(
          (item) => item.type === 'notifications_or_emails'
        ) && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['notifications_or_emails'] || ''}
            onChange={(e) =>
              handleTextAreaChange('notifications_or_emails', e.target.value)
            }
          />
        )}
        <Checkbox className="mt-10" value="Other">
          Other
        </Checkbox>
        {usersFeedback.some((item) => item.type === 'Other') && (
          <TextArea
            placeholder="Please share more details..."
            className="textarea-style"
            autoSize={{ minRows: 3, maxRows: 5 }}
            value={textAreaValues['Other'] || ''}
            onChange={(e) => handleTextAreaChange('Other', e.target.value)}
          />
        )}
      </Checkbox.Group>
      <Divider className="feedbackmodal-divider" />
      <Typography.Text>
        This will permanently delete the{' '}
        <span className="fw-600">{userDetails?.email}</span> account, including
        all your Prep Notes and personal information.
      </Typography.Text>
      <Row>
        <Typography.Text className="mt-10">
          This action cannot be undone.
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default DeleteAccountFeedbackModal;
