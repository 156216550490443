import { CheckCircleFilled } from '@ant-design/icons';
import { Modal, Row, Space, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';
import { connectHubspot } from '../../../utils/IntegrationHelper';
import hubspotLogo from '../../../assets/icons/hubspot.svg';

interface HubspotModal {
  setHubspotModalOpen: Dispatch<SetStateAction<boolean>>;
  hubspotModalOpen: boolean;
  user_id: string;
}
const HubspotConnectModal: React.FC<HubspotModal> = ({
  hubspotModalOpen,
  setHubspotModalOpen,
  user_id,
}) => {
  const handleClose = () => {
    setHubspotModalOpen(false);
  };
  return (
    <Modal
      centered
      width={600}
      open={hubspotModalOpen}
      onCancel={handleClose}
      closable={false}
      footer={
        <>
          <SecondaryButton
            text="Skip, I'll Do it Later"
            onClick={() => setHubspotModalOpen(false)}
          />
          <PrimaryButton
            text="Connect to Hubspot"
            onClick={() => connectHubspot(user_id, 'notification')}
          />
        </>
      }
    >
      <Row align="middle" justify="center" className="mt-10">
        <img src={hubspotLogo} alt="slack" />
        <Typography.Title level={3} className="mb-0 ml-10">
          Hubspot
        </Typography.Title>
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Text className="fs-20 fw-500">
          Enhance Your Prep Note with HubSpot
        </Typography.Text>
      </Row>
      <Row justify="center" align="middle" className="mt-20">
        <Space align="start" className="mr-10">
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            Using HubSpot data, your notes will provide context <br /> on
            prospects interests, helping you focus on what <br /> matters most
            during calls
          </Typography.Text>
        </Space>
      </Row>
      <Row justify="center" align="middle" className="mt-10 mb-20">
        <Space align="start">
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            Integrating HubSpot adds marketing and lead insights <br /> to your
            meeting prep
          </Typography.Text>
        </Space>
      </Row>
    </Modal>
  );
};

export default HubspotConnectModal;
