import { Row, Typography } from 'antd';
import React from 'react';
import GroupTable from './GroupTable';

const GroupListing: React.FC = () => {
  return (
    <>
      <Row className="mt-24">
        <Typography.Text>
          Assign and manage user roles with customizable permission sets.
        </Typography.Text>
      </Row>
      <GroupTable />
    </>
  );
};

export default GroupListing;
