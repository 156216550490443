import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Steps,
  Typography,
  Row,
  Col,
  Divider,
  Space,
  Skeleton,
  Upload,
  UploadProps,
  message,
  Grid,
} from 'antd';
import { WinrateImages } from '../../assets/Images';
import PrimaryButton from '../../common/PrimaryButton';
import SecondaryButton from '../../common/SecondaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/data';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setUserID } from '../../store/authSlice';
import {
  CheckCircleFilled,
  UploadOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { uploadData } from 'aws-amplify/storage';
import {
  connectCalendar,
  connectHubspot,
  connectSalesForce,
  connectSlack,
} from '../../utils/IntegrationHelper';
import { RootState } from 'store/store';
import moment from 'moment-timezone';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';

const { Title, Text } = Typography;
const { Step } = Steps;
interface CompanyDetails {
  name: string; // Add other properties as needed
  domain: string; // Add other properties as needed
  logoUrl: string; // Add other properties as needed
}

interface SetupAccountProps {
  redirectLoader: boolean; // Define the type of redirectLoader
  setRedirectLoader: Dispatch<SetStateAction<boolean>>;
}

const SetupAccount: React.FC<SetupAccountProps> = ({
  redirectLoader,
  setRedirectLoader,
}) => {
  const {
    authBG,
    loginLogo,
    salesForce,
    hubSpot,
    slack,
    connectedIcon,
    calendarPlaceholder,
  } = WinrateImages.Auth;

  const { stepId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  type ClientSchema = any;
  const client = generateClient<ClientSchema>({ authMode: 'apiKey' });
  const [form] = Form.useForm();
  const [companyDetails, setcompanyDetails] = useState<CompanyDetails | null>(
    null
  );
  const [usersEmail, setUsersEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loader] = useState(false);
  const [uploadLogoFile, setUplodLogoFile] = useState<RcFile | undefined>(
    undefined
  );
  const [imageView, setImageView] = useState<string | undefined>(undefined);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [setep1Loading, setStep1Loading] = useState(false);
  const [calendarLoading] = useState(false);
  const [usersID, setUsersID] = useState<string | undefined>(undefined);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const dispatch = useDispatch();
  const user_id = useSelector((state: RootState) => state.auth.userID);

  //for logo upload
  const props: UploadProps = {
    name: 'file',

    onChange(info) {
      setUploadLogo(true);
      const file = info.fileList[0].originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView(e?.target?.result as string);
        };
        setUplodLogoFile(file);
        reader.readAsDataURL(file);
      }
    },
  };

  const fetchUserID = async () => {
    try {
      const data = await fetchAuthSession();
      const { winrate_id: id = '' }: any = data?.tokens?.idToken?.payload || {};
      if (data) {
        setUsersID(id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //fetch company details from domain name
  const fetchCompanyDetails = async () => {
    setLoading(true);
    setRedirectLoader(true);
    try {
      if (localStorage.getItem('User')) {
        const userEmail = localStorage.getItem('User');
        if (userEmail) {
          setUsersEmail(userEmail);
        }
        const res = await client.queries.getOrganizationDetails({
          domain: userEmail?.split('@')[1],
        });
        setcompanyDetails(JSON.parse(res.data.response));
      } else {
        const data = await fetchAuthSession();
        const { email: userEmail = '' }: any =
          data?.tokens?.idToken?.payload || {};
        setUsersEmail(userEmail);
        const res = await client.queries.getOrganizationDetails({
          domain: userEmail?.split('@')[1],
        });

        setcompanyDetails(JSON.parse(res.data.response));
      }
      setLoading(false);
      setRedirectLoader(false);
    } catch (err: any) {
      setLoading(false);
      setRedirectLoader(false);
    }
  };

  // Navigate to the next step and update the URL
  const nextStep = () => {
    const nextStepIndex = currentStep + 1;
    setCurrentStep(nextStepIndex);
    navigate(`/setup-account/${nextStepIndex}`);
  };

  //create a new organization
  const createOrgandUser = async () => {
    setStep1Loading(true);

    const data = await fetchAuthSession();
    const { winrate_id: cognito_user_id = '' }: any =
      data?.tokens?.idToken?.payload || {};

    const formValue = form.getFieldsValue();
    let uploadImageinS3;
    if (uploadLogoFile) {
      uploadImageinS3 = await uploadData({
        path: `logo/${uploadLogoFile?.name}`,
        data: uploadLogoFile,
      }).result;
    }
    try {
      const input = {
        cognito_user_id,
        first_name: formValue.username.split(' ')[0],
        last_name: formValue.username.split(' ')[1],
        domain: companyDetails?.domain
          ? companyDetails?.domain
          : formValue.domain,
        logo: companyDetails?.logoUrl
          ? companyDetails?.logoUrl
          : uploadImageinS3?.path,
        name: companyDetails?.name || formValue.name,
        email: usersEmail,
        timezone: moment.tz.guess(),
      };

      const result = await client.queries.createUserWithOrganization(input);
      const resp = JSON.parse(result.data.response);
      dispatch(setUserID(resp.user.id));
      localStorage.setItem('user_id', resp.user.id);
      message.success('Organization created successfully');
      nextStep();
      setStep1Loading(false);
    } catch (err: any) {
      setStep1Loading(false);
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
    fetchUserID();
    if (!user_id) {
      const localUserID = localStorage.getItem('user_id');
      if (localUserID) {
        dispatch(setUserID(localUserID));
      }
    }
  }, []);

  // Update the current step based on the stepId from the route
  useEffect(() => {
    if (stepId) {
      setCurrentStep(parseInt(stepId, 10) || 0);
    }
  }, [stepId]);

  const handleStepChange = () => {
    navigate(`/setup-account/${currentStep}`);
  };

  //to seth the  form value
  useEffect(() => {
    form.setFieldsValue({
      name: companyDetails?.name || '',
      domain: companyDetails?.domain || '',
      logo: companyDetails?.logoUrl || '',
    });
  }, [companyDetails]);

  useEffect(() => {
    if (currentStep === 2) {
      const fromAuthCallback = new URLSearchParams(window.location.search);
      const getType = fromAuthCallback.get('connect_type');
      const authSuccess = fromAuthCallback.get('auth');
      const accessDenied = fromAuthCallback.get('error');
      if (getType === 'google_calendar' && authSuccess === 'success') {
        localStorage.setItem('googleConnect', authSuccess as string);
        navigate('/setup-account/2');
        message.success('Google calendar connected successfully');
      } else if (
        getType === 'google_calendar' &&
        authSuccess === 'failed' &&
        accessDenied
      ) {
        navigate('/setup-account/1');
        message.error(
          'Failed to connect with Google Calendar, please try again.'
        );
      }
      if (getType === 'slack') {
        localStorage.setItem('slackConnect', authSuccess as string);
        if (authSuccess === 'failed') {
          message.error('Failed to connect with Slack, please try again.');
        }
        navigate('/setup-account/2');
      } else if (getType === 'salesforce') {
        localStorage.setItem('salesForcceConnect', authSuccess as string);

        if (authSuccess === 'failed') {
          message.error('Failed to connect with Salesforce, please try again.');
        }
        navigate('/setup-account/2');
      } else if (getType === 'hubspot') {
        localStorage.setItem('hubspotConnect', authSuccess as string);

        if (authSuccess === 'failed') {
          message.error('Failed to connect with hubspot, please try again.');
        }
        navigate('/setup-account/2');
      }
    } else if (currentStep === 1) {
      const fromAuthCallback = new URLSearchParams(window.location.search);
      const getType = fromAuthCallback.get('connect_type');
      const authSuccess = fromAuthCallback.get('auth');
      const accessDenied = fromAuthCallback.get('error');

      if (getType === 'google_calendar' && authSuccess === 'success') {
        localStorage.setItem('googleConnect', authSuccess as string);
        navigate('/setup-account/2');
      } else if (authSuccess === 'failed' && accessDenied) {
        navigate('/setup-account/1');
        message.error(
          'Failed to connect with Google Calendar, please try again.'
        );
      }
    }
  }, [currentStep]);

  const redirectToEventList = async () => {
    try {
      const token = await fetchAuthSession();
      if (token?.tokens) {
        localStorage.setItem('token', token?.tokens?.accessToken as any);
        navigate('/');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const steps = [
    {
      title: '',
      content: (
        <Row className={!screens.md ? '' : 'signup-text'}>
          <Col
            md={9}
            xs={24}
            sm={24}
            lg={9}
            xl={9}
            // className={screens.xs ? 'form-padding' : ''}
          >
            <Form
              requiredMark={false}
              layout="vertical"
              form={form}
              onFinish={createOrgandUser}
            >
              <Form.Item
                label="Full name"
                name="username"
                rules={[
                  { required: true, message: 'Please input your full name!' },
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: 'Symbols and number are not allowed',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Company name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}
              >
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : (
                  <Input disabled={companyDetails?.name ? true : false} />
                )}
              </Form.Item>

              <Form.Item
                label="Company website"
                name="domain"
                rules={[
                  {
                    required: true,
                    message: 'Please input your domain!',
                  },
                ]}
              >
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : (
                  <Input disabled={companyDetails?.domain ? true : false} />
                )}
              </Form.Item>
              <Form.Item label="Your logo" name="logo">
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : companyDetails?.logoUrl || uploadLogo ? (
                  <div className="static-image">
                    <img
                      className="company-logo"
                      src={
                        companyDetails?.logoUrl
                          ? companyDetails?.logoUrl
                          : imageView
                      }
                    />
                    {uploadLogo && (
                      <CloseCircleOutlined
                        className="close-icon"
                        onClick={() => {
                          setUploadLogo(false);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  !uploadLogo && (
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Upload Logo</Button>
                    </Upload>
                  )
                )}
              </Form.Item>
              <Form.Item className="signup-text">
                <PrimaryButton
                  text="Continue"
                  disabled={loading}
                  loading={setep1Loading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
    },
    {
      title: '',
      content: (
        <>
          <Row
            justify="space-between"
            className={!screens.lg ? '' : 'calendar-step'}
          >
            <Col md={12} xs={24} sm={24} lg={14} xl={14}>
              <Row align="middle">
                <Typography.Text type="secondary">
                  Connect your Google Calendar to move forward.
                </Typography.Text>
              </Row>
              <Typography.Title
                level={4}
                className={!screens.md ? 'fw-500 mt-10' : 'fw-500'}
              >
                Syncing your calendar allows us to <br /> pull in all your
                scheduled meetings
              </Typography.Title>
              <Space align="baseline" className={!screens.md ? 'mt-10' : ''}>
                <CheckCircleFilled style={{ color: 'green' }} />
                <Text>
                  Automatically generate meeting prep <br /> notes for you ahead
                  of time
                </Text>
              </Space>
              <br />
              <Space className={!screens.md ? 'mt-10' : ''}>
                <CheckCircleFilled style={{ color: 'green' }} />
                <Text>Helping you stay informed and prepared</Text>
              </Space>
            </Col>
            <Col
              md={12}
              xs={24}
              sm={24}
              xl={10}
              lg={10}
              className={!screens.md ? 'mt-10 text-center' : ''}
            >
              <img
                src={calendarPlaceholder}
                alt="calendar"
                className="calendar-placeholder"
              />
            </Col>
          </Row>
          <Row className="signup-text">
            {localStorage.getItem('googleConnect') === 'success' ? (
              <Button type="text" icon={<img src={connectedIcon} alt="" />}>
                Connected
              </Button>
            ) : (
              <PrimaryButton
                loading={calendarLoading}
                text="Connect to Calendar"
                onClick={() => connectCalendar(usersID as string)}
              />
            )}
          </Row>
        </>
      ),
    },
    {
      title: '',
      content: (
        <>
          <Row justify="space-between">
            <Col md={21} sm={24} xs={24} xl={21} lg={21}>
              <Row align="middle">
                <img src={slack} alt="slack" />
                <Title level={5} className="mb-0 ml-10">
                  Slack
                </Title>
              </Row>
              <Text>
                Get instant notifications about important updates and meeting
                reminders directly in Slack.
              </Text>
            </Col>
            {localStorage.getItem('slackConnect') === 'success' ? (
              <Col md={3} sm={24} xs={24} xl={3} lg={3}>
                <Button
                  type="text"
                  icon={<img src={connectedIcon} alt="" />}
                  className={!screens.md ? 'connect-btn' : ''}
                >
                  Connected
                </Button>
              </Col>
            ) : (
              <Col md={2} sm={24} xs={24} xl={2} lg={2}>
                <PrimaryButton
                  className={!screens.md ? 'connect-btn' : ''}
                  text="Connect"
                  onClick={() => connectSlack(usersID as string, 'default')}
                  disabled={loader}
                />
              </Col>
            )}
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col md={21} sm={24} xs={24} xl={21} lg={21}>
              <Row align="middle">
                <img src={salesForce} alt="salesForce" />
                <Title level={5} className="mb-0 ml-10">
                  SalesForce
                </Title>
              </Row>
              <Text>
                Connecting Salesforce lets you bring in valuable CRM data for
                your meeting prep.
              </Text>
            </Col>

            {localStorage.getItem('salesForcceConnect') === 'success' ? (
              <Col md={3} sm={24} xs={24} xl={3} lg={3}>
                <Button
                  type="text"
                  icon={<img src={connectedIcon} alt="" />}
                  className={!screens.md ? 'connect-btn' : ''}
                >
                  Connected
                </Button>
              </Col>
            ) : (
              <Col md={2} sm={24} xs={24} xl={2} lg={2}>
                <PrimaryButton
                  className={!screens.md ? 'connect-btn' : ''}
                  text="Connect"
                  onClick={() =>
                    connectSalesForce(usersID as string, 'default')
                  }
                  disabled={loader}
                />
              </Col>
            )}
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col md={21} sm={24} xs={24} xl={21} lg={21}>
              <Row align="middle">
                <img src={hubSpot} alt="hubSpot" />
                <Title level={5} className="mb-0 ml-10">
                  HubSpot
                </Title>
              </Row>
              <Text>
                Sync up-to-date lead activities and engagement history to enrich
                your prep notes.
              </Text>
            </Col>
            {localStorage.getItem('hubspotConnect') === 'success' ? (
              <Col md={3} sm={24} xs={24} xl={3} lg={3}>
                <Button
                  type="text"
                  icon={<img src={connectedIcon} alt="" />}
                  className={!screens.md ? 'connect-btn' : ''}
                >
                  Connected
                </Button>
              </Col>
            ) : (
              <Col md={2} sm={24} xs={24} xl={2} lg={2}>
                <PrimaryButton
                  className={!screens.md ? 'connect-btn' : ''}
                  text="Connect"
                  onClick={() => connectHubspot(usersID as string, 'default')}
                />
              </Col>
            )}
          </Row>
          <Row className="signup-text thrid-step-footer">
            <SecondaryButton
              text="Skip & Get Started"
              onClick={() => redirectToEventList()}
            />
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="auth-page">
        {!screens.md && <MobileNonAuthHeader />}
        {redirectLoader && (
          <div className="redirect-overlay">
            <div className="loader"></div>
            <p className="ml-10"> Redirecting...</p>
          </div>
        )}
        <div
          className={
            redirectLoader
              ? 'redirect-bg auth-page'
              : !screens.md
                ? 'auth-page inner'
                : 'auth-page'
          }
        >
          {/* Your main content goes here */}
          {!screens.md ? '' : <img src={authBG} alt="" className="auth-bg" />}
          <div className="onboarding-container email-form-container">
            {screens.md && (
              <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
            )}
            <div className="mob-top">
              <Steps
                responsive={false}
                direction="horizontal"
                current={currentStep}
                className={!screens.md ? 'mt-20 mb-20' : 'mb-20'}
                onChange={handleStepChange}
              >
                {steps.map((item, index) => (
                  <Step key={index} title={item.title} />
                ))}
              </Steps>
              {currentStep === 0 && (
                <>
                  <Typography.Title level={4} className="signup-text fw-500">
                    Set Up Your Account
                  </Typography.Title>
                  <Typography.Text className="signup-text text-secondary">
                    Set up in no time and start closing deals faster
                  </Typography.Text>
                </>
              )}
              <div className="step-content mt-24">
                {steps[currentStep].content}
              </div>
            </div>
            <div className="mob-bottom footer-margin">
              <Row
                justify={!screens.md ? 'center' : 'space-between'}
                className="account-footer"
                align="middle"
              >
                <div>
                  <Typography.Text className="mr-5">
                    Terms & Conditions
                  </Typography.Text>
                  <span className="text-secondary">• </span>
                  <Typography.Text className="ml-5">
                    Privacy Policy
                  </Typography.Text>
                </div>
                <Typography.Text className="signup-text fs-12 mt-10">
                  © {new Date().getFullYear()}, Winrate. All Rights Reserved.
                </Typography.Text>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupAccount;
