import { RightOutlined } from '@ant-design/icons';
import { List, Modal, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import DeleteAccountModal from './DeleteAccountModal';

interface ManageAccountModalProps {
  openManageAccountModal: boolean;
  setOpenManageAccountModal: (open: boolean) => void;
  userDetails: any;
  setUsersDetails?: (details: any) => void;
}

const ManageAccountModal: React.FC<ManageAccountModalProps> = ({
  openManageAccountModal,
  setOpenManageAccountModal,
  userDetails,
  setUsersDetails,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancelPlanModal, setCancelPlanModal] = useState(false);
  return (
    <>
      <Modal
        open={openManageAccountModal}
        onCancel={() => setOpenManageAccountModal(false)}
        footer={null}
        className="manageaccount-modal"
      >
        <Row>
          <Typography.Title level={4}>Manage Account</Typography.Title>
        </Row>
        <List>
          {userDetails?.subscription_status === 'paid' && (
            <List.Item
              onClick={() => {
                setCancelPlanModal(true);
                setOpenManageAccountModal(false);
              }}
            >
              <List.Item.Meta
                className="ml-20 cursor-pointer"
                title={
                  <span className="fw-500 fs-16">Cancel subscription</span>
                }
                description={
                  <span className="text-secondary">
                    Your subscription will renew on{' '}
                    {dayjs(userDetails?.next_renewal_date).format(
                      'MMM DD, YYYY'
                    )}
                  </span>
                }
              />
              <div>
                <RightOutlined
                  className="right-icon"
                  onClick={() => {
                    setCancelPlanModal(true);
                    setOpenManageAccountModal(false);
                  }}
                />
              </div>
            </List.Item>
          )}
          <List.Item
            onClick={() => {
              setDeleteModalOpen(true);
              setOpenManageAccountModal(false);
            }}
          >
            <List.Item.Meta
              className="ml-20 cursor-pointer"
              title={<span className="fw-500 fs-16">Delete account</span>}
              description={
                <span className="text-secondary">
                  All your data will be permanently erased, and the account
                  cannot be recovered after deletion.
                </span>
              }
            />
            <div>
              <RightOutlined
                className="right-icon"
                onClick={() => {
                  setDeleteModalOpen(true);
                  setOpenManageAccountModal(false);
                }}
              />
            </div>
          </List.Item>
        </List>
      </Modal>
      <DeleteAccountModal
        setDeleteModalOpen={setDeleteModalOpen}
        deleteModalOpen={deleteModalOpen}
        userDetails={userDetails}
      />
      <CancelSubscriptionModal
        cancelPlanModal={cancelPlanModal}
        setCancelPlanModal={setCancelPlanModal}
        userDetails={userDetails}
        setUsersDetails={setUsersDetails}
      />
    </>
  );
};

export default ManageAccountModal;
