import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  message,
  Grid,
} from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { signInWithRedirect, signOut, signUp } from 'aws-amplify/auth';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';

const Signup: React.FC = () => {
  const { authBG, sidePlaceholder, loginLogo, google } = WinrateImages.Auth;
  const { Title, Text } = Typography;
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [loading, setLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      signOut({
        global: false,
      }).then(async () => {
        localStorage.clear();
        // This will trigger the Google OAuth flow using Amplify
        await signInWithRedirect({
          provider: 'Google',
          customState: 'signup',
        });
      });
    } catch (error: any) {
      message.error(error.message);
      console.error('Error during Google Sign In', error);
    }
  };

  // for signup
  const handleSignup = async (e: { email: string; password: string }) => {
    setLoading(true);
    signOut({
      global: false,
    }).then(async () => {
      localStorage.clear();
      const emailData = e.email.toLowerCase();
      await signUp({
        username: emailData,
        password: e.password,
        options: {
          userAttributes: {
            email: emailData,
          },
          autoSignIn: true,
        },
      })
        .then(async () => {
          setLoading(false);
          localStorage.setItem('User', e.email);
          Navigate('/verify-email');
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    });
  };

  return (
    <div className={`auth-page ${!screens.md ? 'mob-auth-page' : ''}`}>
      {!screens.md && <MobileNonAuthHeader />}
      {screens.md && <img src={authBG} alt="" className="auth-bg" />}
      <Row
        gutter={{
          xs: 0,
          sm: 0,
          md: 16,
        }}
        className="auth-container"
      >
        {/* Left Side: Login Form */}
        <Col xs={24} md={12} className="login-form-container">
          <div className="login-form">
            <div className="mob-top">
              {screens.md && (
                <img
                  src={loginLogo}
                  alt="Winrate Logo"
                  className="logo mb-20"
                />
              )}
              <div className={!screens.xl ? 'text-center' : ''}>
                <Title level={4} className="mb-0">
                  Sign Up
                </Title>
                <Text type="secondary" style={{ color: '#595959' }}>
                  Take Control of Your Sales Day
                </Text>
              </div>

              {/* Google Login Button */}

              <Button
                icon={<img src={google} alt="" />}
                className="google-login-btn"
                block
                onClick={handleGoogleSignIn}
              >
                Continue with Google
              </Button>

              <Divider className="login-divider">or</Divider>

              <Form
                form={form}
                name="login"
                layout="vertical"
                className="login-form-fields"
                requiredMark={false}
                onFinish={(e) => {
                  handleSignup(e);
                }}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    {
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                >
                  <Input placeholder="you@company.com" />
                </Form.Item>

                <Form.Item
                  label="Create a password"
                  name="password"
                  className="mb-0"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                    {
                      min: 8,
                      message: 'Please enter more than 8 characters',
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>

                <Form.Item style={{ marginTop: '15px' }}>
                  <Typography.Text>
                    By signing up, I accept the Winrate{' '}
                    <span className="branding-color fw-600">
                      Terms of Service
                    </span>{' '}
                    and acknowledge the{' '}
                    <span className="branding-color fw-600">
                      Privacy Policy.
                    </span>
                  </Typography.Text>
                </Form.Item>

                <Form.Item className="mt-20">
                  <PrimaryButton
                    text="Sign Up"
                    className="login-btn"
                    loading={loading}
                  />
                </Form.Item>
              </Form>
              <Row justify="center" align="middle">
                <Text className="signup-text">Already have an account? </Text>
                <Button
                  className="signup-link"
                  type="text"
                  onClick={() => Navigate('/login')}
                >
                  {' '}
                  Login
                </Button>
              </Row>
            </div>
            <div className="mob-bottom">
              <div className="footer-links">
                <Typography.Text className="mr-5">
                  Terms & Conditions
                </Typography.Text>
                <span className="text-secondary">• </span>
                <Typography.Text className="ml-5">
                  Privacy Policy
                </Typography.Text>
              </div>
              <Text className="signup-text fs-12 mt-10">
                © {new Date().getFullYear()}, Winrate. All Rights Reserved.
              </Text>
            </div>
          </div>
        </Col>

        {/* Right Side: Image and Icons */}
        {screens.md && (
          <Col xs={0} md={12} className="image-container">
            <div className="image-content">
              <img src={sidePlaceholder} alt="Right Side Illustration" />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Signup;
