import { AINews } from './models';

export const NewsCard = ({ news }: { news: AINews }) => {
  let newsDomain = '';

  if (news.url) {
    newsDomain = new URL(news.url).hostname.replace('www.', '');
  }
  return (
    <div
      style={{
        flex: '0 0 auto',
        cursor: 'pointer',
      }}
      onClick={() => (newsDomain ? window.open(news.url, '_blank') : null)}
    >
      <div className="shadow-card news-card">
        <div className="news-summary">{news.headline}</div>
        <div className="news-source">
          <img
            src={`https://logo.clearbit.com/${newsDomain || news.source}`}
            width="16"
          />
          {news.source}
        </div>
      </div>
    </div>
  );
};
