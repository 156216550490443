/* eslint-disable react/prop-types */
import {
  Checkbox,
  Col,
  DatePicker,
  message,
  Modal,
  ModalProps,
  Row,
  TimePicker,
  Typography,
} from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import { Dispatch, SetStateAction, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

const { Text, Title } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setSelectSnoozeDate: (value: Dayjs) => void;
  selectSnoozeDate: Dayjs;
  setSelectSnoozeTime: Dispatch<SetStateAction<Dayjs>>;
  selectSnoozeTime: Dayjs;
  handleUpdateSetting: (
    pathOrUpdates: string | { path: string; value: any }[],
    value?: any
  ) => Promise<void>;
  setSelectedKeys: (value: number[]) => void;
  selectedKeys: number[];
  settings: any;
  integrationStatus: any;
  getPhoneNumber: any;
}

const Snooze: React.FC<Inummodal> = ({
  onClose,
  setSelectSnoozeDate,
  selectSnoozeDate,
  setSelectSnoozeTime,
  selectSnoozeTime,
  handleUpdateSetting,
  selectedKeys,
  setSelectedKeys,
  settings,
  integrationStatus,
  getPhoneNumber,
}) => {
  const options = [
    {
      key: 1,
      label: 'All',
    },
    {
      key: 2,
      label: 'Email',
    },
    {
      key: 3,
      label: 'Slack',
      disabled: integrationStatus.slack !== 'connected' ? true : false,
    },
    {
      key: 4,
      label: 'Text',
      disabled: !getPhoneNumber,
    },
    {
      key: 5,
      label: 'In_App',
    },
  ];
  const handleCheckboxChange = (checkedKeys: number[]) => {
    if (checkedKeys.includes(1)) {
      // If "All" is selected, select all options
      setSelectedKeys(
        options?.filter((e) => !e.disabled).map((option) => option.key)
      );
    } else if (!checkedKeys.includes(1) && selectedKeys.includes(1)) {
      // If "All" is deselected, unselect all options
      setSelectedKeys([]);
    } else {
      // Otherwise, update the state with the current selection
      setSelectedKeys(checkedKeys);
    }
  };

  const combineDateAndTime = (date: Dayjs, time: string) => {
    const datePart = dayjs(date).format('YYYY-MM-DD');
    const combinedDateTime = `${datePart}T${time}`;
    return combinedDateTime;
  };

  const getSelectedOptionsForBackend = () => {
    const selectedOptions = selectedKeys.includes(1)
      ? options
          .filter((option) => option.key !== 1)
          .map((option) => option.label.toLowerCase())
      : options
          .filter(
            (option) => selectedKeys.includes(option.key) && option.key !== 1
          )
          .map((option) => option.label.toLowerCase());
    const snoozeUntilDateTime =
      selectSnoozeDate && selectSnoozeTime
        ? combineDateAndTime(
            selectSnoozeDate,
            dayjs(selectSnoozeTime).format('HH:mm:ss')
          )
        : null;
    if (snoozeUntilDateTime === null && selectedOptions?.length) {
      message.error('Please select a snooze date-time');
    } else {
      handleUpdateSetting([
        {
          path: 'snooze.snooze_until',
          value: snoozeUntilDateTime ? snoozeUntilDateTime : null,
        },
        {
          path: 'snooze.notification_types',
          value: selectedOptions,
        },
      ]);
      message.success('Notification snooze successfully');
    }
    onClose();
  };

  // Map backend response to keys
  useEffect(() => {
    if (settings?.snooze?.notification_types) {
      const mappedKeys = settings?.snooze?.notification_types
        .map((type: any) => {
          const option = options.find(
            (opt) => opt.label.toLowerCase() === type
          );
          return option?.key;
        })
        .filter(Boolean); // Remove undefined keys

      // Include "All" if all options are selected
      if (mappedKeys.length === options.length - 1) {
        setSelectedKeys([1, ...mappedKeys]);
      } else {
        setSelectedKeys(mappedKeys as number[]);
      }
    }
  }, [settings]);

  const disablePastDates = (current: Dayjs | null): boolean => {
    return current ? current.isBefore(dayjs(), 'day') : false;
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal
      centered
      open={true}
      closable={false}
      footer={
        // eslint-disable-next-line react/jsx-key
        <>
          <SecondaryButton text="Cancel" onClick={handleCancel} />
          <PrimaryButton
            text="Snooze"
            onClick={() => getSelectedOptionsForBackend()}
          />
        </>
      }
      className="success-modal"
    >
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Col span={24}>
          <Title level={4}>Snooze notifications until</Title>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Row style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Text style={{ color: '#8C8C8C' }}>Notification Type</Text>
            <Col style={{ display: 'flex', gap: '16px' }}>
              <Checkbox.Group
                className="vertical-box"
                onChange={handleCheckboxChange}
                value={selectedKeys}
              >
                {options.map((item) => {
                  return (
                    <Checkbox
                      key={item?.key}
                      value={item?.key}
                      disabled={item.disabled}
                    >
                      {item.label}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Text style={{ color: '#8C8C8C' }}>Snooze until</Text>
          <Col style={{ display: 'flex', gap: '12px' }}>
            <DatePicker
              onChange={(e) => {
                setSelectSnoozeDate(e || null);
              }}
              format={'YYYY-MM-DD'}
              value={selectSnoozeDate}
              disabledDate={disablePastDates}
            />
            <TimePicker
              onChange={(time: Dayjs | null) => {
                if (time) setSelectSnoozeTime(time);
              }}
              format="h:mm A"
              value={selectSnoozeTime}
            />
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

export default Snooze;
