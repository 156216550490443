import { Button, Dropdown, Row, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import StatusFilter from './StatusFilter';
import TitleFilter from './TitleFilter';
import DepartmentFilter from './DepartmentFilter';
import TeamFilter from './TeamFilter';
import AccessGroupFilter from './AccessGroupFilter';
import SearchUsers from './SearchUsers';
import EditColumns from './EditColumns';
import UsersCountBlock from './UsersCountBlock';
import UserListingTable from './UserListingTable';
import InviteUserModal from './InviteUserModal';
import CRMModal from './CRMModal';

interface UsersProps {
  inviteModal?: boolean;
  setInviteModal?: boolean;
  crmModal?: boolean;
  setCrmModal?: boolean;
}

const UserTabe: React.FC<UsersProps> = () => {
  const [inviteModal, setInviteModal] = useState(false);
  const [crmModal, setCrmModal] = useState(false);

  const items = [
    {
      label: 'Invite with email',
      key: '1',
      onClick: () => setInviteModal(true),
    },
    {
      label: 'Pull from CRM',
      key: '2',
      onClick: () => setCrmModal(true),
    },
  ];

  return (
    <>
      <UsersCountBlock />
      <Row className="mt-24 userFilters" justify="space-between">
        <Row>
          <StatusFilter />
          <TitleFilter />
          <DepartmentFilter />
          <AccessGroupFilter />
          <TeamFilter />
        </Row>
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button className="createuser-btn">
            <Space>
              Create User
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Row>
      <Row className="edit-search-row" justify="space-between" align="middle">
        <SearchUsers />
        <EditColumns />
      </Row>
      <UserListingTable />
      <InviteUserModal
        inviteModal={inviteModal}
        setInviteModal={setInviteModal}
      />
      <CRMModal crmModal={crmModal} setCrmModal={setCrmModal} />
    </>
  );
};

export default UserTabe;
