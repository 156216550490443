import { getUrl } from 'aws-amplify/storage';
import { debounce } from 'lodash';
import dayjs from 'dayjs';

export const fetchS3ImageURL = async (path: string): Promise<string> => {
  let url = '';
  const linkToStorageFile = await getUrl({
    path,
  });
  url =
    linkToStorageFile.url && linkToStorageFile.url?.href
      ? linkToStorageFile.url?.href
      : '';
  return url;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const relativeDate = new Date(date.getTime() + userTimezoneOffset);
  const today = new Date();

  // Check if the input date is today's date
  const isToday = relativeDate.toDateString() === today.toDateString();

  // If it's today, return "Today"
  if (isToday) {
    return 'Today';
  }

  // Otherwise, format the date as "Day, Month Date" (e.g., Wed, Sep 25)
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short', // e.g., "Wed"
    month: 'short', // e.g., "Sep"
    day: 'numeric', // e.g., "25"
  }).format(relativeDate);

  return formattedDate;
};

export const isToday = (dateString: string) => {
  if (!dateString) return false;
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const relativeDate = new Date(date.getTime() + userTimezoneOffset);
  const today = new Date();

  // Compare the date with today's date
  return relativeDate.toDateString() === today.toDateString();
};

export const formatTimeToLocal = (dateString: string) => {
  if (!dateString) return '';
  const date = new Date(dateString);

  // Format the time according to the user's local time zone
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // This will show time in 12-hour format (e.g., "2:30 PM")
  }).format(date);

  return formattedTime;
};

export function debounceFn(fn: any, delay: any) {
  const debouncedFn = debounce(fn, delay);
  return debouncedFn;
}

const formatTime = (date: string | Date): string => {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diffInMinutes = now.diff(inputDate, 'minute');
  const diffInDays = now.diff(inputDate, 'day');

  if (diffInDays < 1) {
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    }
  } else {
    return inputDate.format('MMM DD');
  }
};

export default formatTime;
