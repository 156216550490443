/* eslint-disable react/prop-types */
import {
  Col,
  Modal,
  Row,
  Typography,
  Button,
  ModalProps,
  Form,
  Input,
  message,
  Grid,
} from 'antd';
import { doPost } from '../../../sdk/Events';
import './notification.less';
import { isValidPhoneNumber } from 'libphonenumber-js'; // Optional for validation
import { useState } from 'react';

const { Text, Title } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setPhoneadd?: (value: boolean) => void;
  setSuccessModal: (value: boolean) => void;
  number: string;
  user_id: string;
  formatPhoneNumber: (value: any) => string;
  getUsersInfo: () => void;
}

const Verify: React.FC<Inummodal> = ({
  onClose,
  setPhoneadd,
  setSuccessModal,
  number,
  user_id,
  formatPhoneNumber,
  getUsersInfo,
}) => {
  const [form] = Form.useForm();
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleCancel = () => {
    onClose();
  };

  const verifyOTP = async () => {
    setVerifyLoader(true);
    const getCode = form.getFieldValue('code');
    if (getCode) {
      try {
        const inputData = {
          phone: `${number}`,
          user_id: user_id,
          otp: getCode,
        };
        const path = 'verify-otp';
        await doPost(path, inputData);
        await getUsersInfo();
        if (setPhoneadd) setPhoneadd(false);
        setSuccessModal(true);
        setVerifyLoader(false);
      } catch (error: any) {
        setVerifyLoader(false);
        message.error(error.message);
      }
    } else {
      setVerifyLoader(false);
      message.error('Verification code is required');
    }
  };

  const resendOTP = async () => {
    setResendLoader(true);
    if (number) {
      if (isValidPhoneNumber(number)) {
        try {
          const inputData = {
            phone: `${number}`,
            user_id: user_id,
          };
          const path = `send-otp`;
          await doPost(path, inputData);
          message.success('OTP resend successfully');
          setResendLoader(false);
        } catch (err) {
          setResendLoader(false);
          console.log(err);
        }
      } else {
        message.error('Please enter a valid phone number');
      }
    } else {
      message.error('Phone number is required');
    }
  };

  return (
    <Modal
      footer={null}
      width={600}
      centered
      open={true}
      onCancel={handleCancel}
      className="addnumber-modal"
    >
      <Row className="addnumber-content">
        <Row
          gutter={12}
          className={
            !screens.md ? 'addnumber-header w-100' : 'addnumber-header'
          }
        >
          <Title level={4}>Add Your Number for SMS</Title>
          <Text>
            A verification code has been sent to{' '}
            <Text strong>{formatPhoneNumber(number)}</Text>. Please enter the
            code to add the number.
          </Text>
          <Col>
            <Form
              layout="vertical"
              className="login-form-fields"
              requiredMark={false}
              form={form}
              onFinish={verifyOTP}
            >
              <Form.Item
                label="Verification code"
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
              >
                <Input placeholder="6-digit verification code" />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} className="addnumber-actions">
            <Button
              className="button"
              type="primary"
              htmlType="submit"
              onClick={verifyOTP}
              loading={verifyLoader}
            >
              Save
            </Button>
            <Form.Item className="signup-text">
              <Button
                type="text"
                className="branding-color"
                onClick={resendOTP}
                loading={resendLoader}
              >
                Resend code
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default Verify;
