import { Button, Popover, Row, Space } from 'antd';
import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import CheckboxComponent from '../../common/CheckboxComponent';
const AccessGroupFilter: React.FC = () => {
  const statusOptions = [
    { label: 'Admin', value: 'Admin' },
    { label: 'Supervisor', value: 'Supervisor' },
    { label: 'Entry Level', value: 'Entry Level' },
  ];

  return (
    <Popover
      overlayClassName="userFilters"
      content={
        <>
          {statusOptions?.map((item, index) => {
            return (
              <Row key={index} className="mt-5 mb-5">
                <CheckboxComponent
                  label={item.label}
                  value={item.value}
                  onChange={(value: string, checked: boolean) =>
                    console.log(value, checked)
                  }
                ></CheckboxComponent>
              </Row>
            );
          })}
        </>
      }
      trigger="click"
      placement="bottom"
    >
      <Button type="text" className="p-0 mr-20">
        <Space>
          Access Group
          <CaretDownOutlined />
        </Space>
      </Button>
    </Popover>
  );
};

export default AccessGroupFilter;
