import { Col, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React from 'react';

const AccessGroupBanner: React.FC = () => {
  const { accessgroupBanner } = WinrateImages.AccessGroupIcons;
  return (
    <Row className="user-banner">
      <Col span={20} className="banner-title">
        <Typography.Title level={3} className="title-font">
          Simplify <span className="team-text title-font">Permissions</span>,
          Strengthen Teams
        </Typography.Title>
        <Typography.Text className="banner-text">
          Create groups, add members, and control permission with ease. Give the
          rigth people the right access - quickly and securly.
        </Typography.Text>
      </Col>
      <Col span={4}>
        <img src={accessgroupBanner} alt="" />
      </Col>
    </Row>
  );
};

export default AccessGroupBanner;
