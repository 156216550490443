import { Form, Input, Modal, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';

interface EditModalProps {
  editTeamModal: boolean;
  setEditTeamModal: Dispatch<SetStateAction<boolean>>;
}

const EditTeamModal: React.FC<EditModalProps> = ({
  editTeamModal,
  setEditTeamModal,
}) => {
  return (
    <Modal
      centered
      closable={false}
      open={editTeamModal}
      onCancel={() => setEditTeamModal(false)}
      footer={
        <>
          <SecondaryButton text="Cancel" />
          <PrimaryButton text="Save" />
        </>
      }
    >
      <Typography.Title level={5} className="fw-500">
        Edit Team Info
      </Typography.Title>
      <Form layout="vertical" className="mt-20">
        <Form.Item label="Team name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTeamModal;
