import { Tabs } from 'antd';
import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import '../users.less';
import UserTabe from '../components/UserTabe';
import UserBanner from '../components/Banners/UserBanner';
import TeamBanner from '../components/Banners/TeamBanner';
import TeamListing from '../components/Teams/TeamListing';
import AccessGroupBanner from '../components/Banners/AccessGroupBanner';
import GroupListing from '../components/AccessGroup/GroupListing';

const Users: React.FC = () => {
  const [activeKey, setActiveKey] = useState('1');
  const {
    usersTab,
    usersTabActive,
    teamTab,
    teamTabActive,
    accessGroupTab,
    accessGroupTabActive,
  } = WinrateImages.UsersIcons;

  const items = [
    {
      key: '1',
      label: 'Users',
      icon:
        activeKey === '1' ? (
          <img src={usersTabActive} alt="" />
        ) : (
          <img src={usersTab} alt="" />
        ),
      children: <UserTabe />,
    },
    {
      key: '2',
      label: 'Teams',
      icon:
        activeKey === '2' ? (
          <img src={teamTabActive} alt="" className="tab-icon-height" />
        ) : (
          <img src={teamTab} alt="" />
        ),
      children: <TeamListing />,
    },

    {
      key: '3',
      label: 'Access Group',
      icon:
        activeKey === '3' ? (
          <img src={accessGroupTabActive} alt="" className="tab-icon-height" />
        ) : (
          <img src={accessGroupTab} alt="" />
        ),
      children: <GroupListing />,
    },
  ];

  return (
    <div className="users">
      {activeKey === '1' ? (
        <UserBanner />
      ) : activeKey === '2' ? (
        <TeamBanner />
      ) : (
        <AccessGroupBanner />
      )}
      <Tabs
        className="mt-24"
        defaultActiveKey="1"
        items={items}
        onChange={(e) => setActiveKey(e)}
      />
    </div>
  );
};

export default Users;
