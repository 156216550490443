import React, { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  Radio,
  Row,
  Switch,
  TimePicker,
  Typography,
  RadioChangeEvent,
  message,
  Button,
  Flex,
  Skeleton,
  Grid,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Reorder, useMotionValue, useDragControls } from 'framer-motion';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { doPost, doGet } from '../../../sdk/Events';
import './general.less';
import {
  BlockItem,
  ItemType,
  ItemProps,
  ReorderIconProps,
} from './GeneralTypes';

interface GeneralSettingsProps {
  key: string; // Add key prop to force re-render
}

const { Title, Text } = Typography;

const GeneralSettings: React.FC<GeneralSettingsProps> = ({ key }) => {
  console.log(key);
  const [dayOfMeetingTime, setDayOfMeetingTime] = useState<Dayjs | null>(
    dayjs('06:00 AM', 'hh:mm A')
  );
  const [dayBeforeMeetingTime, setDayBeforeMeetingTime] =
    useState<Dayjs | null>(dayjs('06:00 AM', 'hh:mm A'));
  const [dayOfMeetingEnabled, setDayOfMeetingEnabled] = useState(true);
  const [items, setItems] = useState<ItemType[]>([]);
  const [internalMeeting, setInternalMeeting] = useState(false);
  const [loading, setLoading] = useState(false);
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const addUpdateGeneralAPI = async () => {
    setLoading(true);
    const payload = {
      feature: 'general',
      user_id: userDetails?.userInfo?.id,
      preferences: {
        blocks: items.map(({ title, isHidden, identifier }) => ({
          title,
          is_hidden: isHidden,
          identifier,
        })),
        prep_notes: {
          internal_meeting: internalMeeting,
          external_meeting: true,
          timing: {
            ...(dayOfMeetingEnabled
              ? { '0': dayOfMeetingTime?.format('hh:mm A') }
              : { '1': dayBeforeMeetingTime?.format('hh:mm A') }),
          },
        },
      },
    };
    try {
      const path = `setting`;
      const response = await doPost(path, payload);
      if (response) {
        message.success('General Settings Updated Successfully');
        fetchGeneralTabSetting();
      } else {
        message.error('General Settings Update Failed');
      }
    } catch (error) {
      message.error('An error occurred while updating General Settings.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateTimeChange = (e: RadioChangeEvent) => {
    const isDayOfMeeting = e.target.value === 'dayOfMeeting';
    setDayOfMeetingEnabled(isDayOfMeeting);

    if (isDayOfMeeting) {
      if (!dayOfMeetingTime || dayOfMeetingTime.isValid() === false) {
        setDayOfMeetingTime(dayjs('06:00 AM', 'hh:mm A'));
      }
    } else {
      if (!dayBeforeMeetingTime || dayBeforeMeetingTime.isValid() === false) {
        setDayBeforeMeetingTime(dayjs('06:00 AM', 'hh:mm A'));
      }
    }
  };

  const handleDayOfMeetingTimeChange = (time: Dayjs | null) => {
    setDayOfMeetingTime(time);
  };

  const handleDayBeforeMeetingTimeChange = (time: Dayjs | null) => {
    setDayBeforeMeetingTime(time);
  };
  const handleMeetingSwitchChange = (type: 'internal', checked: boolean) => {
    if (type === 'internal') setInternalMeeting(checked);
  };

  const updateOrderNumbers = (newOrder: typeof items) => {
    const updatedItems = newOrder.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    setItems(updatedItems);
  };

  const toggleItemDisabled = (id: number) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, isHidden: !item.isHidden } : item
    );
    setItems(updatedItems);
  };

  const fetchGeneralTabSetting = async () => {
    setLoading(true);
    const path = `setting/general/${userDetails?.userInfo?.id}`;
    try {
      const response = await doGet(path);
      if (response) {
        const { blocks, prep_notes } = response.data[0].preferences;

        const updatedItems = (blocks as BlockItem[]).map((block, index) => ({
          id: index + 1,
          title: block.title,
          order: index + 1,
          isHidden: block.is_hidden,
          identifier: block.identifier,
        }));
        setItems(updatedItems);
        setInternalMeeting(prep_notes.internal_meeting);
        setDayOfMeetingTime(dayjs(prep_notes.timing[0], 'hh:mm A'));
        setDayBeforeMeetingTime(dayjs(prep_notes.timing[1], 'hh:mm A'));
        setDayOfMeetingEnabled(!!prep_notes.timing[0]);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeneralTabSetting();
  }, []);

  return (
    <Row
      className="general-settings"
      style={{ marginTop: screens.md ? '30px' : '0' }}
    >
      <Col className="ant-col">
        <Title level={5}>Customized View</Title>
        <Text>
          Organize your Prep Note layout by dragging to reorder sections, or
          choose to hide them for a cleaner view.
        </Text>
        {loading ? (
          <Skeleton
            active
            paragraph={{ rows: 6 }}
            style={{ marginTop: '15px' }}
          />
        ) : (
          <Reorder.Group
            axis="y"
            onReorder={updateOrderNumbers}
            values={items}
            className="dragged-wrapper"
          >
            {items.map((item) => (
              <Item key={item.id} item={item} onToggle={toggleItemDisabled} />
            ))}
          </Reorder.Group>
        )}
      </Col>

      <Divider style={{ maxWidth: '70%', backgroundColor: '#D9D9D9' }} />

      <div className="prep-note-settings" style={{ width: '100%' }}>
        <Row gutter={[0, { xs: 12, md: 24 }]}>
          <Col span={24}>
            <Title level={5}>Prep Note Settings</Title>
          </Col>

          <div className="ant-row settings switches" style={{ width: '100%' }}>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Text style={{ fontWeight: 500, fontSize: '15px' }}>
                    Choose which meetings are eligible for Prep Notes
                  </Text>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 8]} className="switch_row">
                    <Col span={22}>
                      <Text>External Meetings</Text>
                    </Col>
                    <Col span={2} className="cust_switch">
                      <Switch
                        checked={true}
                        // onChange={(checked) =>
                        //   handleMeetingSwitchChange('external', checked)
                        // }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]} className="switch_row">
                    <Col span={22}>
                      <Text>Internal Meetings</Text>
                    </Col>
                    <Col span={2} className="cust_switch">
                      <Switch
                        checked={internalMeeting}
                        onChange={(checked) =>
                          handleMeetingSwitchChange('internal', checked)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24} style={{ marginTop: '16px' }}>
                  <Text style={{ fontWeight: 500, fontSize: '15px' }}>
                    Choose when to generate Prep Notes
                  </Text>
                </Col>

                <Col span={24}>
                  <Row gutter={[0, 8]} style={{ marginBottom: '10px' }}>
                    <Col xs={24} lg={12}>
                      <Flex justify="space-between" align="center">
                        <Radio.Group
                          onChange={handleGenerateTimeChange}
                          value={
                            dayOfMeetingEnabled ? 'dayOfMeeting' : 'dayBefore'
                          }
                        >
                          <Radio value="dayOfMeeting">
                            Generate on the day of meeting
                          </Radio>
                        </Radio.Group>
                        <TimePicker
                          value={dayOfMeetingEnabled ? dayOfMeetingTime : null}
                          onChange={handleDayOfMeetingTimeChange}
                          disabled={!dayOfMeetingEnabled}
                          format="hh:mm A"
                        />
                      </Flex>
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]}>
                    <Col xs={24} lg={12}>
                      <Flex justify="space-between" align="center">
                        <Radio.Group
                          onChange={handleGenerateTimeChange}
                          value={
                            !dayOfMeetingEnabled ? 'dayBefore' : 'dayOfMeeting'
                          }
                        >
                          <Radio value="dayBefore">
                            Generate on the day before meeting
                          </Radio>
                        </Radio.Group>
                        <TimePicker
                          value={
                            !dayOfMeetingEnabled ? dayBeforeMeetingTime : null
                          }
                          onChange={handleDayBeforeMeetingTimeChange}
                          disabled={dayOfMeetingEnabled}
                          format="hh:mm A"
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>

        <Row>
          <Col span={24} style={{ marginTop: '20px' }}>
            <Button
              type="primary"
              loading={loading}
              onClick={addUpdateGeneralAPI}
              className="primary_btn"
              style={{ marginTop: '15px' }}
            >
              Save Settings
            </Button>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const Item: React.FC<ItemProps> = ({ item, onToggle }) => {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  const handleToggle = () => {
    if (item.id !== undefined) {
      onToggle(item.id);
    }
  };

  return (
    <Reorder.Item
      value={item}
      style={{ y }}
      dragListener={!item.isHidden}
      dragControls={dragControls}
      className={`draggable-item ${item.isHidden ? 'disabled' : ''}`}
    >
      <div
        className="drag-action"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {!item.isHidden && <ReorderIcon dragControls={dragControls} />}
        <span style={{ marginLeft: '10px' }}>
          <h4
            style={{ textDecoration: item.isHidden ? 'line-through' : 'none' }}
          >
            {item.title}
          </h4>
        </span>
      </div>

      {item.isHidden ? (
        <EyeInvisibleOutlined
          onClick={handleToggle}
          style={{
            marginLeft: 'auto',
            cursor: 'pointer',
            color: 'gray',
            textDecoration: 'none',
          }}
        />
      ) : (
        <EyeOutlined
          onClick={handleToggle}
          style={{
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        />
      )}
    </Reorder.Item>
  );
};

const ReorderIcon: React.FC<ReorderIconProps> = ({ dragControls }) => (
  <span onPointerDown={(e) => dragControls.start(e)} style={{ cursor: 'grab' }}>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8268 4.00001C11.1814 4.0034 10.6682 4.5363 10.6704 5.20112C10.6726 5.87007 11.1978 6.40336 11.848 6.39697C12.4875 6.39058 13.0047 5.84715 13 5.18646C12.9952 4.5254 12.4725 3.99701 11.8268 4.00001Z"
        fill="#262626"
      />
      <path
        d="M7.17321 16C7.81934 15.9966 8.33143 15.4644 8.3296 14.7989C8.32777 14.1303 7.80181 13.5959 7.15203 13.6027C6.51321 13.609 5.99564 14.1528 6.00003 14.8135C6.00441 15.4735 6.52818 16.0034 7.17321 16Z"
        fill="#262626"
      />
      <path
        d="M11.8315 15.9998C12.4769 16.0006 12.997 15.4696 12.9996 14.8081C13.0022 14.1425 12.4755 13.6006 11.8286 13.6025C11.1872 13.6044 10.6697 14.141 10.67 14.804C10.6708 15.4688 11.1858 15.9991 11.8315 15.9998Z"
        fill="#262626"
      />
      <path
        d="M8.32987 5.19323C8.32767 4.52841 7.81158 3.99926 7.16581 4.00001C6.51603 4.00076 5.99555 4.54044 6.00029 5.20901C6.00504 5.86707 6.53174 6.40035 7.17385 6.39734C7.81596 6.39434 8.33206 5.85692 8.32987 5.19323Z"
        fill="#262626"
      />
      <path
        d="M8.32987 10.0041C8.33169 9.34149 7.81705 8.80482 7.17348 8.79806C6.53721 8.79167 6.00468 9.33209 6.00029 9.98902C5.99591 10.655 6.52005 11.2003 7.16581 11.2018C7.80573 11.2033 8.32804 10.6659 8.32987 10.0041Z"
        fill="#262626"
      />
      <path
        d="M12.9996 10.0018C13.0003 9.34299 12.4806 8.8037 11.8388 8.79768C11.1967 8.79167 10.6675 9.33848 10.6704 10.0052C10.673 10.6662 11.1971 11.2044 11.8355 11.2018C12.4762 11.1992 12.9989 10.6606 12.9996 10.0018Z"
        fill="#262626"
      />
    </svg>
  </span>
);

export default GeneralSettings;
