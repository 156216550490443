import { Row, Col, Typography, Grid } from 'antd';
import SecondaryButton from '../../../common/SecondaryButton';
import { useState } from 'react';
import ManageAccountModal from './ManageAccountModal';

const ManageAccount = ({
  userDetails,
  setUsersDetails,
}: {
  userDetails: any;
  setUsersDetails?: (details: any) => void;
}) => {
  const [openManageAccountModal, setOpenManageAccountModal] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <div className="manage-account">
      <Row>
        <Typography.Title level={5} className="fw-600">
          Manage Account
        </Typography.Title>
      </Row>
      <Row className={!screens.md ? '' : 'text-row'}>
        <Col md={21} lg={21} xs={24} sm={24}>
          <Typography.Text className="p-10">
            Manage your account and all associated data. <br /> This action is
            irreversible.
          </Typography.Text>
        </Col>
        <Col md={3} lg={3} xs={24} sm={24}>
          <SecondaryButton
            className={!screens.md ? 'mt-10' : ''}
            text="Manage"
            onClick={() => setOpenManageAccountModal(true)}
          />
        </Col>
      </Row>
      <ManageAccountModal
        userDetails={userDetails}
        openManageAccountModal={openManageAccountModal}
        setOpenManageAccountModal={setOpenManageAccountModal}
        setUsersDetails={setUsersDetails}
      />
    </div>
  );
};

export default ManageAccount;
