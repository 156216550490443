import { Row } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import React, { useState } from 'react';
import CreatedDateFilter from './CreatedDateFilter';
import MyTeamFilter from './MyTeamFilter';
import CreateTeamModal from './CreateTeamModal';
import SearchComponent from '../../../common/SearchComponent';
import TeamCardList from './TeamCardList';

const TeamListing: React.FC = () => {
  const [createTeamModal, setCreateTeamModal] = useState(false);
  return (
    <>
      <Row className="mt-24" justify="space-between">
        <Row>
          <MyTeamFilter />
          <CreatedDateFilter />
        </Row>
        <PrimaryButton
          text="Create Team"
          onClick={() => setCreateTeamModal(true)}
        />
      </Row>
      <Row className="mt-12">
        <SearchComponent placeholder="Search team" />
      </Row>
      <Row className="mt-12">
        <TeamCardList />
      </Row>
      <CreateTeamModal
        createTeamModal={createTeamModal}
        setCreateTeamModal={setCreateTeamModal}
      />
    </>
  );
};

export default TeamListing;
