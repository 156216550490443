import { Skeleton } from 'antd';

export const Skeletons = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Skeleton active={true} />
      <Skeleton active={true} />
    </div>
  );
};

export function formatMeetingDate(startTime: string, endTime: string): any {
  if (!startTime || !endTime) {
    return null;
  }
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const formattedDate = dateFormatter.format(startDate);
  const formattedStartTime = timeFormatter.format(startDate);
  const formattedEndTime = timeFormatter.format(endDate);

  const day = startDate.getDate();
  const daySuffix =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
          ? 'rd'
          : 'th';

  return (
    <>
      {formattedDate.replace(/\d+/, day + daySuffix)}{' '}
      <span className="lil-divider">|</span> {formattedStartTime} -{' '}
      {formattedEndTime}
    </>
  );
}

export function timeAgo(timestamp: string): string {
  const now = new Date();
  const time = new Date(timestamp);
  const diffInMs = Math.abs(now.getTime() - time.getTime());

  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const days = Math.floor(diffInMinutes / (60 * 24));
  const hours = Math.floor((diffInMinutes % (60 * 24)) / 60);
  const minutes = diffInMinutes % 60;

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
}
