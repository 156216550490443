import { Button, Modal, Typography } from 'antd';
import RedButton from '../../../common/RedButton';
import React, { Dispatch, SetStateAction } from 'react';

interface RemoveUserProps {
  setRemoveUserModal: Dispatch<SetStateAction<boolean>>;
  removeUserModal: boolean;
}

const RemoveUserModal: React.FC<RemoveUserProps> = ({
  setRemoveUserModal,
  removeUserModal,
}) => {
  return (
    <Modal
      centered
      closable={false}
      open={removeUserModal}
      footer={
        <>
          <Button
            className="remove-cancel-btn"
            onClick={() => setRemoveUserModal(false)}
          >
            Cancel
          </Button>
          <RedButton text="Remove" />
        </>
      }
    >
      <Typography.Title level={5} className="fw-500 mb-20">
        Remove users
      </Typography.Title>
      <Typography.Text>
        Are you sure you want to remove the selected users?
      </Typography.Text>
    </Modal>
  );
};

export default RemoveUserModal;
