import { Col, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React from 'react';

const UserBanner: React.FC = () => {
  const { bannerImage } = WinrateImages.UsersIcons;
  return (
    <Row className="user-banner">
      <Col span={20} className="banner-title">
        <Typography.Title level={3} className="title-font">
          Keep Your <span className="team-text">Team</span> on the Same Page!
        </Typography.Title>
        <Typography.Text className="banner-text">
          Easily manage roles and titles so everyone knows their part in the big
          picture.
        </Typography.Text>
      </Col>
      <Col span={4}>
        <img src={bannerImage} alt="" />
      </Col>
    </Row>
  );
};

export default UserBanner;
