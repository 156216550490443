import { Button, Modal, Typography } from 'antd';
import RedButton from '../../../common/RedButton';
import React, { Dispatch, SetStateAction } from 'react';

interface DeleteModalProps {
  setDeleteTeamModal: Dispatch<SetStateAction<boolean>>;
  deleteTeamModal: boolean;
}

const DeleteTeamModal: React.FC<DeleteModalProps> = ({
  setDeleteTeamModal,
  deleteTeamModal,
}) => {
  return (
    <Modal
      open={deleteTeamModal}
      onCancel={() => setDeleteTeamModal(false)}
      closable={false}
      centered
      footer={
        <>
          <Button
            className="remove-cancel-btn"
            onClick={() => setDeleteTeamModal(false)}
          >
            Cancel
          </Button>
          <RedButton text="Remove" />
        </>
      }
    >
      <Typography.Title level={5} className="fw-500">
        Delete Team
      </Typography.Title>
      <Typography.Text>
        Are you sure you want to delete this team?
      </Typography.Text>
    </Modal>
  );
};

export default DeleteTeamModal;
