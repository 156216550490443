import { WinrateImages } from '../assets/Images';
const MobileNonAuthHeader: React.FC = () => {
  const { loginLogo } = WinrateImages.Auth;
  return (
    <div className="mobile_nonauth_header">
      <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
    </div>
  );
};
export default MobileNonAuthHeader;
