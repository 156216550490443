import React from 'react';
import BegginingResearchItem from './BegginingResearchItem';
import ResearchItems from './ResearchItems';
import { Skeleton } from 'antd';
import { ResearchProps } from './CustomResearchTypes';

const Research: React.FC<ResearchProps> = ({ customResearchs, loading }) => {
  return (
    <div className="custom_research_add mt-24">
      {loading ? (
        <Skeleton active paragraph={{ rows: 10 }} />
      ) : customResearchs?.length === 0 ? (
        <BegginingResearchItem customResearchs={customResearchs} />
      ) : (
        <ResearchItems customResearchs={customResearchs} />
      )}
    </div>
  );
};

export default Research;
