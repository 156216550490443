import { Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import SecondaryButton from '../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';

interface EditModalProp {
  editModal?: boolean;
  setEditModal: Dispatch<SetStateAction<boolean>>;
}

const EditUserModal: React.FC<EditModalProp> = ({
  editModal,
  setEditModal,
}) => {
  return (
    <Modal
      closable={false}
      width={900}
      open={editModal}
      onCancel={() => setEditModal(false)}
      footer={
        <>
          <SecondaryButton text="Cancel" onClick={() => setEditModal(false)} />
          <PrimaryButton text="Save" onClick={() => setEditModal(false)} />
        </>
      }
    >
      <Row>
        <Typography.Title level={5}>Edit User Dipal Jani</Typography.Title>
      </Row>
      <Form layout="vertical">
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="First name">
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name">
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Status">
              <Select placeholder="Select status">
                <Select.Option key="1">Entry Level 1</Select.Option>
                <Select.Option key="2">Entry Level 2</Select.Option>
                <Select.Option key="3">Entry Level 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email Id">
              <Input placeholder="Enter email id" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Access Group">
              <Select placeholder="Select access group" mode="multiple">
                <Select.Option key="1">Entry Level 1</Select.Option>
                <Select.Option key="2">Entry Level 2</Select.Option>
                <Select.Option key="3">Entry Level 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone">
              <Input placeholder="Enter phone" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Supervisor">
              <Input placeholder="Enter supervisor" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Title">
              <Input placeholder="choose title" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Team">
              <Select placeholder="Select team" mode="multiple">
                <Select.Option key="1">Entry Level 1</Select.Option>
                <Select.Option key="2">Entry Level 2</Select.Option>
                <Select.Option key="3">Entry Level 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Department">
              <Select placeholder="Select department">
                <Select.Option key="1">Entry Level 1</Select.Option>
                <Select.Option key="2">Entry Level 2</Select.Option>
                <Select.Option key="3">Entry Level 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
