import { Button, Popover, Row, Space } from 'antd';
import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import CheckboxComponent from '../../common/CheckboxComponent';
const TeamFilter: React.FC = () => {
  const statusOptions = [
    { label: 'Revenue Rockstars', value: 'Revenue Rockstars' },
    { label: 'Sales Ninja', value: 'Sales Ninja' },
    { label: 'Profit Pirates', value: 'Profit Pirates' },
  ];

  return (
    <Popover
      overlayClassName="userFilters"
      content={
        <>
          {statusOptions?.map((item, index) => {
            return (
              <Row key={index} className="mb-5 mt-5">
                <CheckboxComponent
                  label={item.label}
                  value={item.value}
                  onChange={(value: string, checked: boolean) =>
                    console.log(value, checked)
                  }
                ></CheckboxComponent>
              </Row>
            );
          })}
        </>
      }
      trigger="click"
      placement="bottom"
    >
      <Button type="text" className="p-0 mr-20">
        <Space>
          Teams
          <CaretDownOutlined />
        </Space>
      </Button>
    </Popover>
  );
};

export default TeamFilter;
