import { Button, Flex, Grid, message, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import videoLogo from '../../../assets/icons/camera.svg';
import BegginingResearchItem from './BegginingResearchItem';
import { doDelete } from '../../../sdk/Events';
import { useDispatch, useSelector } from 'react-redux';
import {
  setisResearchAdded,
  setisFirstResearch,
} from '../../../store/customResearchSlice';
import { RootState } from 'store/store';
import ImagePopup from './ImagePopup';
import Sailor from '../../../assets/icons/Sailor.svg';
import Welcome from './Welcome';
import { ResearchsProps, ResearchItem } from './CustomResearchTypes';
import SecondaryButton from '../../../common/SecondaryButton';
import { PlusOutlined } from '@ant-design/icons';
import SubscriptionExpiredModal from '../../../MyAccount/components/Subscription/SubscriptionExpiredModal';

const ResearchItems: React.FC<ResearchsProps> = ({ customResearchs }) => {
  const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const [isCancelEditModal, setIsCancelEditModal] = useState(false);
  const [customResearchsItems, setCustomResearchsItems] =
    useState(customResearchs);

  const [removeLoading, setRemoveLoading] = useState<Record<string, boolean>>(
    {}
  );
  const [editingItem, setEditingItem] = useState<ResearchItem | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const isFirstResearch = useSelector(
    (state: RootState) => state.customResearch.isFirstResearch
  );
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const dispatch = useDispatch();
  const showResearchModal = () => {
    setEditingItem(null);
    setIsResearchModalOpen(true);
  };
  const handleCancel = () => {
    setEditingItem(null);
    setIsResearchModalOpen(false);
    setIsCancelEditModal((prevState) => !prevState);
  };
  const handleEdit = (item: ResearchItem) => {
    setEditingItem(item);
    setIsResearchModalOpen(true);
  };
  const onResearchDelete = async (id: string) => {
    setRemoveLoading((prevState) => ({ ...prevState, [id]: true }));
    const path = `setting/${id}`;
    try {
      const response = await doDelete(path);
      if (response) {
        setTimeout(() => {
          const updatedItems = customResearchsItems.filter(
            (item) => item?.id !== id
          );
          setCustomResearchsItems(updatedItems);

          if (updatedItems.length === 0) {
            dispatch(setisResearchAdded(true));
          }

          message.success('Custom Research Deleted Successfully');
        }, 1000);
      } else {
        message.error('An error occurred while deleting the Custom Research.');
        setRemoveLoading((prevState) => ({ ...prevState, [id]: false }));
      }
    } catch (error: any) {
      const { statusCode, body } = error.response;
      if (
        statusCode === 403 &&
        JSON.parse(body as string).error ===
          'Access denied: Subscription has ended.'
      ) {
        setShowEndSubscriptionModal(true);
      }
      console.error('Error:', error);
      message.error('Failed to delete research.');
      setRemoveLoading((prevState) => ({ ...prevState, [id]: false }));
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };
  useEffect(() => {
    if (isFirstResearch) {
      setIsPopupVisible(true);
    }
  }, [isFirstResearch]);

  useEffect(() => {
    if (customResearchs.length !== 0) {
      dispatch(setisFirstResearch(false));
    }
  }, [customResearchs]);

  return (
    <div className="custom_research_items mt-24">
      <div>
        <Flex
          align="center"
          justify="space-between"
          style={{ marginBottom: '20px' }}
        >
          <Typography.Title
            level={5}
            className="title"
            style={{ marginBottom: 0 }}
          >
            Your Custom Research
          </Typography.Title>
          {screens.md ? (
            <Flex>
              <Button
                className="settings_action_btn tutorial"
                onClick={() => setIsTutorialModalOpen(true)}
              >
                <img src={videoLogo} />
                Tutorial
              </Button>
              <Button
                className="settings_action_btn"
                style={{ marginLeft: '15px' }}
                onClick={showResearchModal}
              >
                New Research
              </Button>
            </Flex>
          ) : (
            <Flex>
              <Button
                className="settings_action_btn mob tutorial"
                onClick={() => setIsTutorialModalOpen(true)}
              >
                <img src={videoLogo} />
              </Button>
              <Button
                className="settings_action_btn mob"
                style={{ marginLeft: '15px' }}
                onClick={showResearchModal}
              >
                <PlusOutlined />
              </Button>
            </Flex>
          )}
        </Flex>
        {customResearchsItems.length > 0 ? (
          customResearchsItems.map((item) => (
            <div key={item.id} className="research_list_item">
              <div className="research_list_item_inner">
                <h3>{item.title}</h3>
                <p className="desc">{item.prompt}</p>
                {item?.sources?.links?.length > 0 && (
                  <ul>
                    {item?.sources?.links?.map((link, index) => (
                      <li key={index}>{link}</li>
                    ))}
                  </ul>
                )}
              </div>
              <Flex
                className="actions"
                align="center"
                justify="end"
                style={{ width: '100%' }}
              >
                <SecondaryButton onClick={() => handleEdit(item)} text="Edit" />
                <SecondaryButton
                  className="ml-10"
                  text="Remove"
                  onClick={() => onResearchDelete(item.id)}
                  loading={removeLoading[item.id] === true}
                />
              </Flex>
            </div>
          ))
        ) : (
          <div className="no-research-message">
            <Typography.Text>
              No custom research items available.
            </Typography.Text>
          </div>
        )}
      </div>
      <Modal
        open={isTutorialModalOpen}
        title={null} // Remove the header
        onCancel={() => setIsTutorialModalOpen(false)}
        className="modal_tutorial"
        centered
        footer={null}
        maskClosable={false}
        width={720}
      >
        <Welcome />
      </Modal>
      <Modal
        open={isResearchModalOpen}
        title={null} // Remove the header
        closable={false}
        className="modal_research"
        centered
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        width={720}
      >
        <BegginingResearchItem
          isModal={true}
          setIsResearchModalOpen={setIsResearchModalOpen}
          editingItem={editingItem}
          customResearchs={customResearchs}
          isCancelEditModal={isCancelEditModal}
        />
      </Modal>
      <ImagePopup
        img={Sailor}
        title={
          <>
            Congratulations on Your <span className="team-text">First</span>{' '}
            Research! 🎉
          </>
        }
        desc="We re diving deep into the questions you ve asked. Your questions are
          the compass guiding us to meaningful discoveries - lets dive in!"
        isOpen={isPopupVisible}
        onClose={closePopup}
      />
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={userDetails?.userInfo?.id}
      />
    </div>
  );
};

export default ResearchItems;
