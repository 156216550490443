import { Form, Input, Modal, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';

interface GroupModalProps {
  setEditGroupModal: Dispatch<SetStateAction<boolean>>;
  editGroupModal: boolean;
}

const EditGroupModal: React.FC<GroupModalProps> = ({
  editGroupModal,
  setEditGroupModal,
}) => {
  const { TextArea } = Input;

  return (
    <Modal
      open={editGroupModal}
      onCancel={() => setEditGroupModal(false)}
      footer={
        <>
          <SecondaryButton text="Cancel" />
          <PrimaryButton text="Save" />
        </>
      }
      closable={false}
    >
      <>
        <Typography.Title level={5} className="fw-500">
          Edit Access Group Info
        </Typography.Title>
        <Form layout="vertical" requiredMark={true} className="mt-20">
          <Form.Item label="Access group name">
            <Input />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};

export default EditGroupModal;
