import { Modal, Row, Select, Table, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import SecondaryButton from '../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';
import SearchComponent from '../../common/SearchComponent';

interface CrmProps {
  crmModal: boolean;
  setCrmModal: Dispatch<SetStateAction<boolean>>;
}

const CRMModal: React.FC<CrmProps> = ({ crmModal, setCrmModal }) => {
  return (
    <Modal
      open={crmModal}
      onCancel={() => setCrmModal(false)}
      closable={false}
      width={900}
      footer={
        <>
          <SecondaryButton text="Cancel" onClick={() => setCrmModal(false)} />
          <PrimaryButton text="Invite" onClick={() => setCrmModal(false)} />
        </>
      }
      className="crm-modal"
    >
      <Typography.Title className="fw-500" level={5}>
        Pull from CRM
      </Typography.Title>
      <Typography.Text>
        Select aceess group :{' '}
        <Select className="ml-10 access-group" mode="multiple">
          <Select.Option key="1">Group 1</Select.Option>
          <Select.Option key="2">Group 2</Select.Option>
          <Select.Option key="3">Group 3</Select.Option>
        </Select>{' '}
      </Typography.Text>
      <Row className="search-row">
        <SearchComponent placeholder="Search name or email address" />
      </Row>
      <Table />
    </Modal>
  );
};

export default CRMModal;
