import {
  Avatar,
  Button,
  Dropdown,
  Menu,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import EditUserModal from './EditUserModal';
import TitleChangeModal from './TitleChangeModal';

interface DataType {
  key: string;
  name: string;
  email: string;
  avatar: string;
  status: string;
  title: string;
  department: string;
  accessGroup: string;
  team: string;
  supervisor: string;
  lastActivity: string;
  phone: string;
  createdDate: string;
}

const data: DataType[] = [
  {
    key: '1',
    name: 'Jacob Jones',
    email: 'nevaeh.simmons@example.com',
    avatar: 'https://placehold.co/32x32',
    status: 'Active',
    title: 'Admin',
    department: '-',
    accessGroup: 'Admin',
    team: 'Revenue Rockstars',
    supervisor: '-',
    lastActivity: '2 hours ago',
    phone: '123-456-7890',
    createdDate: '2023-08-01',
  },
];

const UserListingTable = () => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: DataType) => (
        <>
          <Space>
            <Avatar src={record.avatar} />
            <div>
              <div>{text}</div>
              <Typography.Text
                style={
                  hoveredRow === record.key
                    ? {
                        width: 100,
                      }
                    : undefined
                }
                ellipsis={
                  hoveredRow === record.key
                    ? {
                        tooltip: text,
                      }
                    : false
                }
                className="ml-5"
              >
                <span>{record.email}</span>
              </Typography.Text>
            </div>
          </Space>
          {hoveredRow === record.key ? (
            <>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={() => setEditModal(true)}>
                      Edit
                    </Menu.Item>
                    <Menu.Item key="2">Remove</Menu.Item>
                  </Menu>
                }
              >
                <Button className="action-btn">
                  Actions <DownOutlined className="down-arrow-icon" />
                </Button>
              </Dropdown>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status: string) => (
        <Tag
          className="status-tag"
          color={status === 'Active' ? 'green' : 'red'}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (title: string) => (
        <span>
          {title}
          <DownOutlined
            className="down-arrow-icon"
            onClick={() => setTitleModal(true)}
          />
        </span>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      sorter: true,
    },
    {
      title: 'Access Group',
      dataIndex: 'accessGroup',
      key: 'accessGroup',
      sorter: true,
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      sorter: true,
    },
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      key: 'supervisor',
      sorter: true,
    },
    {
      title: 'Last Activity',
      dataIndex: 'lastactivity',
      key: 'lastactivity',
      sorter: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table
        className="userTable"
        columns={columns}
        rowSelection={rowSelection}
        dataSource={data}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
        }}
        scroll={{ x: 1500 }}
        onRow={(record) => {
          return {
            onMouseEnter: () => setHoveredRow(record.key),
            onMouseLeave: () => setHoveredRow(null),
          };
        }}
      />
      <EditUserModal editModal={editModal} setEditModal={setEditModal} />
      <TitleChangeModal setTitleModal={setTitleModal} titleModal={titleModal} />
    </>
  );
};

export default UserListingTable;
