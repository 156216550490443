import { Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import React, { Dispatch, SetStateAction } from 'react';
import SecondaryButton from '../../common/SecondaryButton';

interface InviteModalProps {
  inviteModal?: boolean;
  setInviteModal: Dispatch<SetStateAction<boolean>>;
}

const InviteUserModal: React.FC<InviteModalProps> = ({
  inviteModal,
  setInviteModal,
}) => {
  return (
    <Modal
      width={900}
      open={inviteModal}
      onCancel={() => setInviteModal(false)}
      closable={false}
      footer={
        <>
          <SecondaryButton
            text="Cancel"
            onClick={() => setInviteModal(false)}
          />
          <PrimaryButton text="Invite" onClick={() => setInviteModal(false)} />
        </>
      }
    >
      <Typography.Title level={5}>Invite User</Typography.Title>
      <Form layout="vertical">
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="First name">
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name">
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Email Id">
              <Input placeholder="Enter email id" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Access Group">
              <Select placeholder="Select access group">
                <Select.Option key="1">Entry Level 1</Select.Option>
                <Select.Option key="2">Entry Level 2</Select.Option>
                <Select.Option key="3">Entry Level 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Supervisor">
              <Input placeholder="Enter supervisor" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Title">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
