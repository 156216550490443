import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { WinrateImages } from '../../../assets/Images';

interface TeamModalProp {
  setCreateTeamModal: Dispatch<SetStateAction<boolean>>;
  createTeamModal: boolean;
}

const CreateTeamModal: React.FC<TeamModalProp> = ({
  createTeamModal,
  setCreateTeamModal,
}) => {
  const { profileIcon, createTeamBanner } = WinrateImages.TeamIcons;

  const [coverImage, setCoverImage] = useState<string | undefined>(undefined);

  const props: UploadProps = {
    name: 'file',
    accept: 'image/*',
    multiple: true,
    onChange(info) {
      const file = info.fileList[0].originFileObj;
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setCoverImage(e.target.result as string);
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  };
  return (
    <Modal
      width={950}
      closable={false}
      open={createTeamModal}
      onCancel={() => setCreateTeamModal(false)}
      footer={
        <>
          <SecondaryButton text="Cancel" />
          <PrimaryButton text="Save" />
        </>
      }
      className="create-team-modal"
    >
      <Typography.Title level={5} className="fw-500 modal-title mb-0">
        Create Team
      </Typography.Title>
      <div className="div-wrapper">
        <img
          alt=""
          src={coverImage ? coverImage : createTeamBanner}
          className="cover-image"
        />
        <Upload {...props}>
          <Button>Upload Cover</Button>
        </Upload>
      </div>

      <Row>
        <Col style={{ position: 'relative' }} span={2}>
          <Avatar
            size={64}
            src={profileIcon}
            className="modal-title avtar-icon"
          ></Avatar>
          <EditFilled className="edit-icon" />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form
            className="teams-form"
            id="createTeamForm"
            layout="vertical"
            initialValues={{ teamName: '', teamMembers: [] }}
          >
            <Form.Item
              name="teamName"
              label="Team name"
              rules={[
                { required: true, message: 'Please enter the team name' },
              ]}
            >
              <Input placeholder="Enter team name" />
            </Form.Item>

            <Form.Item
              name="teamMembers"
              label="Team members"
              rules={[
                { required: true, message: 'Please select team members' },
              ]}
            >
              <Select
                placeholder="Select team members"
                mode="multiple"
                allowClear
              ></Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateTeamModal;
