import { Button, Divider, Popover, Row, Typography } from 'antd';
import React from 'react';
import { HolderOutlined } from '@ant-design/icons';
import CheckboxComponent from '../../common/CheckboxComponent';

const EditColumns: React.FC = () => {
  const columnArray = [
    {
      text: 'Status',
      icon: <HolderOutlined />,
    },
    {
      text: 'Title',
      icon: <HolderOutlined />,
    },
    {
      text: 'Department',
      icon: <HolderOutlined />,
    },
    {
      text: 'Team',
      icon: <HolderOutlined />,
    },
    {
      text: 'Access Group',
      icon: <HolderOutlined />,
    },
    {
      text: 'Supervisor',
      icon: <HolderOutlined />,
    },
    {
      text: 'Last Active',
      icon: <HolderOutlined />,
    },
    {
      text: 'Phone',
      icon: <HolderOutlined />,
    },
    {
      text: 'Created Date',
      icon: <HolderOutlined />,
    },
  ];
  return (
    <Popover
      overlayClassName="edit-column"
      title={
        <Row justify="space-between" align="middle">
          <Typography.Text className="fw-500">Show Columns</Typography.Text>
          <Button type="text" className="save-btn">
            Save
          </Button>
        </Row>
      }
      content={
        <>
          <Divider className="column-divider mb-10" />

          {columnArray?.map((item, index) => {
            return (
              <Row key={index} className="mb-10">
                <div className="mr-5">{item?.icon} </div>
                <CheckboxComponent
                  label={item.text}
                  value={item?.text}
                  onChange={(value: string, checked: boolean) =>
                    console.log(value, checked)
                  }
                ></CheckboxComponent>
              </Row>
            );
          })}
        </>
      }
      trigger="click"
      placement="bottom"
    >
      <Button className="edit-column-btn">Edit Columns</Button>
    </Popover>
  );
};
export default EditColumns;
