import {
  Breadcrumb,
  Button,
  Dropdown,
  Row,
  Space,
  Tabs,
  Typography,
} from 'antd';

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import UsersDetails from './UsersDetails';
import PermissionDetails from './PermissionDetails';
import EditGroupModal from './EditGroupModal';

const GroupDetailPage: React.FC = () => {
  const { usersTab, usersTabActive, teamTab, teamTabActive } =
    WinrateImages.UsersIcons;
  const location = useLocation();
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const items: MenuProps['items'] = [
    {
      label: 'Edit Group Info',
      key: '1',
      onClick: () => setEditGroupModal(true),
    },
  ];

  const menuProps = {
    items,
  };

  const groupItems = [
    {
      key: '1',
      label: 'Users',
      icon:
        activeKey === '1' ? (
          <img src={usersTabActive} alt="" />
        ) : (
          <img src={usersTab} alt="" />
        ),
      children: <UsersDetails />,
    },
    {
      key: '2',
      label: 'Permissions',
      icon:
        activeKey === '2' ? (
          <img src={teamTabActive} alt="" className="tab-icon-height" />
        ) : (
          <img src={teamTab} alt="" />
        ),
      children: <PermissionDetails />,
    },
  ];

  return (
    <div className="groupdetail">
      <Breadcrumb className="header-breadcrumb">
        <Breadcrumb.Item>
          <Link to="/user-management">
            <LeftOutlined className="mr-10" />
            Access Groups
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Details</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="mt-24" justify="space-between" align="middle">
        <div>
          <Typography.Title level={4} className="mb-0">
            {location?.state?.data?.name}
          </Typography.Title>
          <Typography.Text className="text-secondary">
            {location?.state?.data?.description}
          </Typography.Text>
        </div>
        <Dropdown
          menu={menuProps}
          className="action-button"
          trigger={['click']}
        >
          <Button>
            <Space>
              Actions
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Row>
      <Tabs
        className="mt-24"
        defaultActiveKey="1"
        items={groupItems}
        onChange={(e) => setActiveKey(e)}
      />
      <EditGroupModal
        editGroupModal={editGroupModal}
        setEditGroupModal={setEditGroupModal}
      />
    </div>
  );
};

export default GroupDetailPage;
