import { Card, Col, Divider, Image, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TeamCardList: React.FC = () => {
  const { createTeamBanner, salesNinja, dealCloser } = WinrateImages.TeamIcons;
  const navigate = useNavigate();
  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card
          hoverable
          className="custom-card"
          cover={
            <div className="card-cover">
              <img
                alt="example"
                src={salesNinja} // Replace with your actual image URL
                className="cover-image"
              />
            </div>
          }
        >
          <Card.Meta title="Sales Ninjas" description="Created by: Arthur" />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          onClick={() => navigate('/team-detail')}
          hoverable
          className="custom-card"
          cover={
            <div className="card-cover">
              <img
                alt="example"
                src={dealCloser} // Replace with your actual image URL
                className="cover-image"
              />
            </div>
          }
        >
          <>
            <div>
              <Image
                src="https://api.dicebear.com/7.x/miniavs/svg?seed=8"
                preview={false}
                className="user-profile-avatar"
              />
            </div>
            <Typography.Text className="mt-10 desc-text">
              Sales Ninja
            </Typography.Text>
            <Row className="card-desc-row mt-10" justify="space-between">
              <Col span={7} className="text-center">
                <Typography.Text className="active-text">8</Typography.Text>
                <Row>
                  <Typography.Text className="desc-text">
                    Active Deals
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={1} className="card-divider">
                <Divider type="vertical" />
              </Col>
              <Col span={7} className="text-center">
                <Typography.Text className="won-text">10</Typography.Text>
                <Row>
                  <Typography.Text className="desc-text">
                    Closed Won
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={1} className="card-divider">
                <Divider type="vertical" />
              </Col>
              <Col span={8} className="text-center">
                <Typography.Text className="lost-text">14</Typography.Text>
                <Row>
                  <Typography.Text className="desc-text">
                    Closed Lost
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
            <Row className="mt-10" justify="space-between">
              <Row>
                <Typography.Text>Created by:</Typography.Text>
                <img
                  className="card-img ml-10"
                  src="https://api.dicebear.com/7.x/miniavs/svg?seed=8"
                  alt=""
                />
                <Typography.Text className="ml-10">Dipal Jani</Typography.Text>
              </Row>
              <Typography.Text>3 Members</Typography.Text>
            </Row>
          </>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          hoverable
          className="custom-card"
          cover={
            <div className="card-cover">
              <img
                alt="example"
                src={createTeamBanner} // Replace with your actual image URL
                className="cover-image"
              />
            </div>
          }
        >
          <Card.Meta title="Sales Ninjas" description="Created by: Arthur" />
        </Card>
      </Col>
    </Row>
  );
};

export default TeamCardList;
