import { Col, Row, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import videoBg from '../../../assets/icons/video_bg.png';
import playBg from '../../../assets/icons/play.svg';

const Welcome: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState<boolean>(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
        setShowControls(true);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleVideoEnd = () => {
    setShowControls(false);
    setIsPlaying(false);
  };
  return (
    <div className="custom_research_welcome mt-24">
      <Row>
        <Col span={24}>
          <div className="custom_research_welcome_item title-font">
            <Typography.Title level={3} className="title-font">
              Welcome to <span className="team-text">Custom</span> Research
            </Typography.Title>
            <Typography.Paragraph className="description-font">
              Unlock AI-driven insights with custom questions and specific links
              for targeted research. <br /> Check out the video below to set up
              Custom Research.
            </Typography.Paragraph>
            <div
              style={{
                position: 'relative',

                margin: '0 auto',
              }}
            >
              <video
                poster={videoBg}
                ref={videoRef}
                controls={showControls}
                onEnded={handleVideoEnd}
              >
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div
                className="mask"
                style={{
                  display: isPlaying ? 'none' : 'flex',
                }}
              >
                <div
                  onClick={handlePlayPause}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    cursor: 'pointer',
                    display: isPlaying ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={playBg} className="play_img" />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Welcome;
