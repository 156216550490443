import React from 'react';
import { Button, Popover, Row, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
const MyTeamFilter: React.FC = () => {
  return (
    <Row>
      <Popover
        overlayClassName="userFilters"
        content={
          <>
            <Row className="mt-5 mb-5">
              <Button type="text" className="p-0">
                My Teams
              </Button>
            </Row>
            <Row>
              <Button type="text" className="p-0">
                All Teams
              </Button>
            </Row>
          </>
        }
        trigger="click"
        placement="bottom"
      >
        <Button type="text" className="p-0  mr-20">
          <Space>
            My Teams
            <CaretDownOutlined />
          </Space>
        </Button>
      </Popover>
    </Row>
  );
};

export default MyTeamFilter;
