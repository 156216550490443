import { ApiError, get, post, patch, del, put } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

let showAccessDeniedModal: (() => void) | null = null;

export const setAccessDeniedCallback = (callback: (() => void) | null) => {
  showAccessDeniedModal = callback;
};

export const doGet = async (path: string): Promise<any> => {
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';

  try {
    const restOperation = get({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    throw error;
    // return null;
  }
};

export const doPost = async (path: string, payload: any): Promise<any> => {
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';

  try {
    const restOperation = post({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
        body: payload,
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error: any) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;
        if (
          statusCode === 403 &&
          JSON.parse(body as string).error ===
            'Access denied: Subscription has ended.'
        ) {
          if (showAccessDeniedModal) {
            showAccessDeniedModal();
          }
        }
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    throw error;
    // return null;
  }
};

export const doDelete = async (path: string): Promise<any> => {
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';
  try {
    const restOperation = await del({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
      },
    });
    const response = await restOperation.response;
    return response;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;

        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    throw error;
    // return null;
  }
};

export const doPatch = async (path: string, payload: any): Promise<any> => {
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';

  try {
    const restOperation = patch({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
        body: payload,
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;

        if (
          statusCode === 403 &&
          JSON.parse(body as string).error ===
            'Access denied: Subscription has ended.'
        ) {
          if (showAccessDeniedModal) {
            showAccessDeniedModal();
          }
        }
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    throw error;
    // return null;
  }
};

export const getMeetingNotes = async (summaryId: string): Promise<any> => {
  return await doGet(`events/${summaryId}`);
};

export const createMeetingNotes = async (payload: any): Promise<any> => {
  return await doPost('events', payload);
};

export const updateMeeting = async (
  summaryId: string,
  payload: any
): Promise<any> => {
  return await doPut(`events/${summaryId}`, payload);
};

export const doPut = async (path: string, payload?: any): Promise<any> => {
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';

  try {
    const restOperation = put({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
        body: payload,
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;
        if (
          statusCode === 403 &&
          JSON.parse(body as string).error ===
            'Access denied: Subscription has ended.'
        ) {
          if (showAccessDeniedModal) {
            showAccessDeniedModal();
          }
        }
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    throw error;
    // return null;
  }
};
