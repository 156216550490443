import { Avatar, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
const UsersGridView: React.FC = () => {
  return (
    <Row gutter={[16, 16]} className="gridview mt-12" justify="space-between">
      <Col span={8} className="grid-card">
        <Space size="middle">
          <Avatar
            size={64}
            src="https://randomuser.me/api/portraits/women/44.jpg" // Example avatar image URL
          />
          <div>
            <Typography.Title
              level={5}
              style={{ marginBottom: 0 }}
              className="fw-500"
            >
              Jessica Brown
            </Typography.Title>
            <Typography.Text type="secondary">
              Sales Rep | Sales
            </Typography.Text>
          </div>
        </Space>

        <div style={{ marginTop: '16px' }}>
          <MailOutlined />
          <Typography.Text className="text-space">
            larry@verizon.net
          </Typography.Text>
          <PhoneOutlined className="ml-10" />
          <Typography.Text className="text-space">
            (308) 555-0121
          </Typography.Text>

          <div style={{ marginTop: '16px' }}>
            <Typography.Text type="secondary">
              Access group:- <span className="text-primary">Supervisor L1</span>
            </Typography.Text>
            <br />
            <Typography.Text type="secondary">
              Supervisor:-<span className="text-primary"> Ronald Richards</span>
            </Typography.Text>
          </div>
        </div>
      </Col>
      <Col span={8} className="grid-card"></Col>
      <Col span={8} className="grid-card"></Col>
    </Row>
  );
};

export default UsersGridView;
