import { Modal, Row, Space, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { connectSalesForce } from '../../../utils/IntegrationHelper';
import salesforceLogo from '../../../assets/icons/Salesforce.svg';

interface SalesforceModal {
  setSaleforceModalOpen: Dispatch<SetStateAction<boolean>>;
  salesforceModalOpen: boolean;
  user_id: string;
}

const SalesforceConnectModal: React.FC<SalesforceModal> = ({
  setSaleforceModalOpen,
  salesforceModalOpen,
  user_id,
}) => {
  const handleClose = () => {
    setSaleforceModalOpen(false);
  };
  return (
    <Modal
      centered
      width={600}
      open={salesforceModalOpen}
      onCancel={handleClose}
      closable={false}
      footer={
        <>
          <SecondaryButton
            text="Skip, I'll Do it Later"
            onClick={() => setSaleforceModalOpen(false)}
          />
          <PrimaryButton
            text="Connect to Salesforce"
            onClick={() => connectSalesForce(user_id, 'notification')}
          />
        </>
      }
    >
      <Row align="middle" justify="center" className="mt-10">
        <img src={salesforceLogo} alt="slack" />
        <Typography.Title level={3} className="mb-0 ml-10">
          Salesforce
        </Typography.Title>
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Text className="fs-20 fw-500">
          Enhance Your Prep Note with Salesforce
        </Typography.Text>
      </Row>
      <Row justify="center" align="middle" className="mt-20">
        <Space align="start" className="mr-30">
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            We&apos;ll pull contact details, deal stages, and recent <br />{' '}
            interactions to tailor your meeting prep notes.
          </Typography.Text>
        </Space>
      </Row>
      <Row justify="center" align="middle" className="mt-10 mb-20">
        <Space align="start">
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            Prep notes provide a deeper understanding of each <br /> client,
            enabling you to make personalized connections.
          </Typography.Text>
        </Space>
      </Row>
    </Modal>
  );
};

export default SalesforceConnectModal;
