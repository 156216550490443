import { Empty, Skeleton } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// internal imports
import { WinrateImages } from '../../../assets/Images';

export const CatchUp = ({
  loading,
  title,
  summary,
  emailNotes,
  crmNotes,
  crmProvider,
  transcriptNotes,
}: {
  loading: boolean;
  title?: string;
  summary: string;
  emailNotes: string;
  crmNotes: string;
  crmProvider: string;
  transcriptNotes: string;
}) => {
  return (
    <div className="note-context">
      {loading ? (
        <Skeleton.Input
          active={true}
          size={'default'}
          block={true}
          style={{ marginBottom: '20px' }}
        />
      ) : (
        <h2 className="section-title">{title}</h2>
      )}
      {loading && !summary && <Skeleton active={true} />}
      {!loading && !summary && !emailNotes && !crmProvider && !crmNotes && (
        <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
      )}
      <div>{summary}</div>
      {emailNotes && (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'flex-start',
          }}
        >
          <img
            src={WinrateImages.Icons.gmailIcon}
            alt="GMail logo"
            width="20px"
            style={{ padding: '4px 0px' }}
          />
          <div>{emailNotes}</div>
        </div>
      )}

      {crmProvider && crmNotes && (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'flex-start',
          }}
        >
          {crmProvider === 'hubspot' && (
            <img
              src={WinrateImages.Icons.hubspotIcon}
              alt="Hubspot logo"
              width="20px"
              style={{ padding: '4px 0px' }}
            />
          )}
          {crmProvider === 'salesforce' && (
            <img
              src={WinrateImages.Auth.salesForce}
              alt="Salesforce logo"
              width="20px"
              style={{ padding: '4px 0px' }}
            />
          )}
          <div>{crmNotes}</div>
        </div>
      )}

      {transcriptNotes && (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'flex-start',
          }}
        >
          <div className="transcript-notes">
            <Markdown remarkPlugins={[remarkGfm]}>
              {'Here are the transcript summaries from previous meetings:\n\n\n' +
                transcriptNotes}
            </Markdown>
          </div>
        </div>
      )}
    </div>
  );
};
