import { Modal, Row, Table, Typography } from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';
import TitleFilter from '../TitleFilter';
import DepartmentFilter from '../DepartmentFilter';
import SearchComponent from '../../../common/SearchComponent';

interface AssignModalProps {
  setAssignUserModal: Dispatch<SetStateAction<boolean>>;
  assignUserModal: boolean;
}

const AssignUserModal: React.FC<AssignModalProps> = ({
  setAssignUserModal,
  assignUserModal,
}) => {
  return (
    <Modal
      width={950}
      open={assignUserModal}
      onCancel={() => setAssignUserModal(false)}
      closable={false}
      footer={
        <>
          <SecondaryButton text="Cancel" />
          <PrimaryButton text="Save" />
        </>
      }
    >
      <>
        <Typography.Title level={5} className="fw-500">
          Assign Users
        </Typography.Title>
        <TitleFilter />
        <DepartmentFilter />
        <Row className="edit-search-row" justify="space-between" align="middle">
          <SearchComponent placeholder="Search name or email address " />
        </Row>
        <Table
          pagination={{
            pageSize: 10,
          }}
        />
      </>
    </Modal>
  );
};

export default AssignUserModal;
