import { Avatar, Form, Modal, Row, Select, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import SecondaryButton from '../../common/SecondaryButton';
import React, { Dispatch, SetStateAction } from 'react';

interface TitleModalProp {
  setTitleModal: Dispatch<SetStateAction<boolean>>;
  titleModal?: boolean;
}

const TitleChangeModal: React.FC<TitleModalProp> = ({
  titleModal,
  setTitleModal,
}) => {
  return (
    <Modal
      closable={false}
      open={titleModal}
      onCancel={() => setTitleModal(false)}
      footer={
        <>
          <SecondaryButton text="Cancel" onClick={() => setTitleModal(false)} />
          <PrimaryButton text="Save" onClick={() => setTitleModal(false)} />
        </>
      }
    >
      <Row align="middle">
        <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
        <Typography.Title level={5} className="fw-500 ml-10 mb-0">
          Dipal Jani
        </Typography.Title>
      </Row>
      <Form layout="vertical" className="mt-20">
        <Form.Item label="Title">
          <Select>
            <Select.Option value="Manager">Manager</Select.Option>
            <Select.Option value="Developer">Developer</Select.Option>
            <Select.Option value="Tester">Tester</Select.Option>
            <Select.Option value="Designer">Designer</Select.Option>
            <Select.Option value="QA">QA</Select.Option>
            <Select.Option value="UX/UI Designer">UX/UI Designer</Select.Option>
            <Select.Option value="Data Scientist">Data Scientist</Select.Option>
            <Select.Option value="Product Owner">Product Owner</Select.Option>
            <Select.Option value="Project Manager">
              Project Manager
            </Select.Option>
            <Select.Option value="Scrum Master">Scrum Master</Select.Option>
            <Select.Option value="Full Stack Developer">
              Full Stack Developer
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TitleChangeModal;
