import { Empty, Skeleton } from 'antd';

export const Questions = ({
  loading,
  title,
  questions,
}: {
  loading: boolean;
  title?: string;
  questions: any[];
}) => {
  if (!loading && !questions.length) return null;

  return (
    <div className="note-questions">
      {loading ? (
        <Skeleton.Input
          active={true}
          size={'default'}
          block={true}
          style={{ marginBottom: '20px' }}
        />
      ) : (
        <h2 className="section-title">{title}</h2>
      )}
      {!loading && questions.length === 0 && (
        <Empty style={{ border: '1px solid #ddd', padding: '15px' }} />
      )}
      {loading && !questions.length && (
        <>
          <Skeleton.Node
            active={true}
            style={{ width: '60%', height: '20px', marginBottom: '5px' }}
          />
          <Skeleton.Node
            active={true}
            style={{ width: '60%', height: '20px', marginBottom: '5px' }}
          />
          <Skeleton.Node
            active={true}
            style={{ width: '60%', height: '20px', marginBottom: '5px' }}
          />
        </>
      )}

      {questions && questions.length > 0 && (
        <ol className="note-questions-list">
          {(questions || []).map((question: string, i: number) => (
            <li key={`question-${i}`}>{question}</li>
          ))}
        </ol>
      )}
    </div>
  );
};
