import { Grid, Modal, Row, Space, Typography } from 'antd';
import React, { Dispatch, memo, SetStateAction } from 'react';
import slackLogo from '../../../assets/icons/Slack.svg';
import SecondaryButton from '../../../common/SecondaryButton';
import PrimaryButton from '../../../common/PrimaryButton';
import { CheckCircleFilled } from '@ant-design/icons';
import { connectSlack } from '../../../utils/IntegrationHelper';

interface SlackModal {
  setSlackModalOpen: Dispatch<SetStateAction<boolean>>;
  slackModalOpen: boolean;
  user_id: string;
  integrationStatus: any;
  disconnectIntegration: (e: any) => Promise<void>;
}

const SlackConnectModal: React.FC<SlackModal> = ({
  setSlackModalOpen,
  slackModalOpen,
  user_id,
  integrationStatus,
  disconnectIntegration,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleClose = () => {
    setSlackModalOpen(false);
  };

  return (
    <Modal
      centered
      width={600}
      open={slackModalOpen}
      onCancel={handleClose}
      closable={false}
      footer={
        <>
          <SecondaryButton
            text="Skip, I'll Do it Later"
            onClick={() => setSlackModalOpen(false)}
          />
          <PrimaryButton
            text={
              integrationStatus?.slack === 'connected'
                ? 'Disconnect Slack'
                : 'Connect to Slack'
            }
            onClick={() =>
              integrationStatus.slack === 'connected'
                ? disconnectIntegration('slack')
                : connectSlack(user_id, 'notification')
            }
          />
        </>
      }
    >
      <Row align="middle" justify="center" className="mt-10">
        <img src={slackLogo} alt="slack" />
        <Typography.Title level={3} className="mb-0 ml-10">
          Slack
        </Typography.Title>
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Text className="fs-20 fw-500">
          Unlock Key Insights with Slack Integration{' '}
        </Typography.Text>
      </Row>
      <Row justify="center" align="middle" className="mt-20">
        <Space align="start" className="mr-20">
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            Get instant notifications about important updates and <br /> meeting
            reminders directly in Slack.
          </Typography.Text>
        </Space>
      </Row>
      <Row justify="center" align="middle" className="mt-10 mb-20">
        <Space align="start" className={screens.lg ? 'ml-10' : ''}>
          <CheckCircleFilled style={{ color: 'green' }} className="fs-16" />
          <Typography.Text>
            Quickly access key information and prep notes for meetings <br />{' '}
            shared right in your workspace.
          </Typography.Text>
        </Space>
      </Row>
    </Modal>
  );
};

export default memo(SlackConnectModal);
