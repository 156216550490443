import { Col, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React from 'react';

const TeamBanner: React.FC = () => {
  const { teamBannerImage } = WinrateImages.TeamIcons;
  return (
    <Row className="user-banner">
      <Col span={20} className="banner-title">
        <Typography.Title level={3} className="title-font">
          Build <span className="team-text title-font">Teams</span>, Boost your
          Winrate
        </Typography.Title>
        <Typography.Text className="banner-text">
          Track team performance with insights into closed won, lost, and active
          deals.
        </Typography.Text>
      </Col>
      <Col span={4}>
        <img src={teamBannerImage} alt="" />
      </Col>
    </Row>
  );
};

export default TeamBanner;
