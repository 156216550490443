import { Button, Card, Col, Divider, Grid, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import { useState } from 'react';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import { doPost } from '../../../sdk/Events';
import dayjs from 'dayjs';

const PaymentDetail = ({
  userDetails,
  setUsersDetails,
}: {
  userDetails: any;
  setUsersDetails?: (details: any) => void;
}) => {
  const [cancelPlanModal, setCancelPlanModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renewLoader, setRenewLoader] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const showPaymentDetails = async () => {
    setLoading(true);
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;

      const inputData = {
        user_id: userDetails?.id,
        redirect_url: `${baseURL}/my-account/subscription`,
      };
      const response = await doPost('stripe/portal', inputData);
      window.location.href = response.data;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching payment details:', error);
    }
  };

  const renewSubscription = async () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    setRenewLoader(true);
    try {
      const inputData = {
        success_url: `${baseURL}/my-account/subscription`,
        cancel_url: `${baseURL}/my-account/subscription`,
        user_id: userDetails?.id,
      };
      const response = await doPost('stripe-checkout', inputData);
      window.location.href = response.data;
      setRenewLoader(false);
    } catch (err) {
      setRenewLoader(false);
      console.error('Error fetching purchase plan: ', err);
    }
  };
  return (
    <div className="payment-detail">
      <Row className="mt-30">
        <Typography.Title level={5}>Current Plan</Typography.Title>
      </Row>
      <Card className="payment-detail-card">
        <Row gutter={[16, 16]}>
          <Col md={17} lg={17} sm={24} xs={24}>
            <Typography.Title level={3} className="plan-title mb-0">
              Premium plan
            </Typography.Title>
            <Typography.Text className="text-secondary">
              Valid until{' '}
              {dayjs(userDetails?.next_renewal_date).format('MMM DD, YYYY')}
            </Typography.Text>
            {!screens.md && (
              <Row justify="center" className="mt-10">
                <img
                  src={WinrateImages.SubscriptionIcons.stripePlaceholder}
                  className="placeholder-h"
                />
              </Row>
            )}
            <Row
              justify={!screens.md ? 'center' : 'start'}
              className="mt-20"
              gutter={10}
            >
              <Col>
                <Button
                  className={
                    !screens.md
                      ? 'payment-btn-center payment-detail-btn mb-20'
                      : 'payment-detail-btn'
                  }
                  onClick={showPaymentDetails}
                  loading={loading}
                >
                  Payment Details
                </Button>
              </Col>
              {userDetails?.subscription_status === 'paid' && (
                <Col>
                  <Button
                    className={
                      !screens.md
                        ? 'payment-btn-center payment-detail-btn ml-10'
                        : 'payment-detail-btn ml-10'
                    }
                    onClick={() => setCancelPlanModal(true)}
                  >
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          {screens.md && (
            <Col md={7} lg={7} sm={24} xs={24} className="placeholder-align">
              <img
                src={WinrateImages.SubscriptionIcons.stripePlaceholder}
                className="placeholder-h"
              />
            </Col>
          )}
        </Row>
        {userDetails?.subscription_status === 'canceled' && (
          <>
            <Divider className="payment-detail-divider" />
            <Row className="mt-20" align="middle" justify="space-between">
              <Col md={17} lg={17} xs={24} sm={24}>
                <Typography.Text>
                  Changed your mind? Renew subscription now and pick up where
                  you left off
                </Typography.Text>
              </Col>
              <Col md={7} lg={7} sm={24} xs={24}>
                <Button
                  type="primary"
                  className={
                    !screens.md
                      ? 'purchase-btn mt-10'
                      : 'purchase-btn renew-btn'
                  }
                  onClick={renewSubscription}
                  loading={renewLoader}
                  disabled={renewLoader}
                >
                  Renew Subscription
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <CancelSubscriptionModal
        userDetails={userDetails}
        cancelPlanModal={cancelPlanModal}
        setCancelPlanModal={setCancelPlanModal}
        setUsersDetails={setUsersDetails}
      />
    </div>
  );
};

export default PaymentDetail;
