import { Grid, Modal, Row, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import React from 'react';
import dayjs from 'dayjs';

interface SuccessModalProps {
  setSuccessModalOpen: (open: boolean) => void;
  successModalOpen: boolean;
  userDetails: any;
}
const SuccessModal: React.FC<SuccessModalProps> = ({
  setSuccessModalOpen,
  successModalOpen,
  userDetails,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <Modal
      open={successModalOpen}
      onCancel={() => setSuccessModalOpen(false)}
      footer={null}
      width={600}
    >
      <Row justify="center" className="mt-10">
        <img src={WinrateImages.SubscriptionIcons.successIcon} />
      </Row>
      <Row justify="center" className="mt-20">
        <Typography.Title
          level={3}
          className={
            !screens.md
              ? 'success-modal-text text-center'
              : 'success-modal-text'
          }
        >
          Your subscription has been{' '}
          <span className="wrapper-text">successfully</span> canceled
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text>
          You can still enjoy all premium features until{' '}
          {dayjs(userDetails?.next_renewal_date).format('MMM DD, YYYY')}.
        </Typography.Text>
      </Row>
    </Modal>
  );
};

export default SuccessModal;
