import { WinrateImages } from '../../assets/Images';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Typography, Grid } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';

const ResetPassword: React.FC = (props: any) => {
  const { authBG, loginLogo, resetPassowordIcon } = WinrateImages.Auth;
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ''
  );
  const [loading, setloading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // Function to handle resend code
  const resendConfirmationCode = async () => {
    setResetLoading(true);
    try {
      await resetPassword({ username: email }).then(() => {
        message.success('Your resend code has been sent');
        setResetLoading(false);
      });
    } catch (error: any) {
      message.error(error.message);
      setResetLoading(false);
    }
  };

  // Function to confirmation code submit
  const confirmReset = async (e: {
    email: string;
    code: string;
    password: string;
  }) => {
    setloading(true);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: e.code,
        newPassword: e.password,
      }).then(() => {
        setloading(false);
        navigate('/login', {
          state: { feedback: 'Signin with new password.', userName: '' },
        });
      });
    } catch (error: any) {
      setloading(false);
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes('confirmationcode=') &&
        window.location.href.includes('/reset-password') &&
        window.location.href.includes('email=')
      ) {
        const Data = {
          code: window.location.href
            .split('confirmationcode=')[1]
            .split('&email=')[0],
        };
        form.setFieldsValue({
          code: Data.code,
          password: '',
        });
        setemail(window.location.href.split('email=')[1]);
      } else {
        navigate('/');
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={`auth-page ${!screens.md ? 'mob-auth-page' : ''}`}>
      {!screens.md && <MobileNonAuthHeader />}

      {screens.md && <img src={authBG} alt="" className="auth-bg" />}

      <div className="onboarding-container email-form-container">
        <div className="mob-top">
          {screens.md && (
            <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
          )}
          <div className="mt-24">
            <img src={resetPassowordIcon} alt="" className="logo" />
          </div>
          <Typography.Title level={4} className="mt-24 signup-text">
            Reset Password
          </Typography.Title>
          <Typography.Text className="signup-text text-secondary">
            A verification code has been sent to{'  '}
            <span className="text-primary fw-500 ml-5"> {email}</span>
          </Typography.Text>
          <Typography.Text className="signup-text text-secondary">
            Please enter the code to reset password.
          </Typography.Text>
          <Row className="signup-text mt-24">
            <Col xs={24} md={12}>
              <Form
                name="login"
                layout="vertical"
                className="login-form-fields"
                requiredMark={false}
                form={form}
                onFinish={(e) => confirmReset(e)}
              >
                <Form.Item
                  label="Verification code"
                  name="code"
                  rules={[
                    { required: true, message: 'Please input your code!' },
                  ]}
                >
                  <Input placeholder="6-digit verification code" />
                </Form.Item>
                <Form.Item
                  label="Create a password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Min. 8 characters" />
                </Form.Item>

                <Form.Item className="mt-20 mb-10">
                  <PrimaryButton
                    text="Submit"
                    className="login-btn"
                    loading={loading}
                  />
                </Form.Item>
                <Form.Item className="signup-text">
                  <Button
                    type="text"
                    className="branding-color"
                    onClick={resendConfirmationCode}
                    loading={resetLoading}
                  >
                    Resend code
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mob-bottom">
          <Row className="verify-email-footer">
            <div style={{ marginBottom: '10px' }}>
              <Typography.Text className="mr-5">
                Terms & Conditions
              </Typography.Text>
              <span className="text-secondary">• </span>
              <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
            </div>
            <Typography.Text className="signup-text fs-12">
              © {new Date().getFullYear()}, Winrate. All Rights Reserved.
            </Typography.Text>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
