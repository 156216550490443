import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { WinrateImages } from '../../../assets/Images';
import { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { doGet, doPost } from '../../../sdk/Events';
import { fetchAuthSession } from 'aws-amplify/auth';
import dayjs from 'dayjs';

type FreeTrial = {
  daysLeft: string;
  trialEndDate: string;
};
const PurchasePlan = ({ userDetails }: { userDetails: any }) => {
  const [freeTrialDate, setFreeTrialDate] = useState({} as FreeTrial);
  const [loading, setLoading] = useState(false);
  const fetchFreeTrialStatus = async () => {
    try {
      const response = await doGet('user/trial');
      setFreeTrialDate(response.data);
    } catch (err) {
      console.error('Error fetching free trial status: ', err);
    }
  };

  const purchasePlan = async () => {
    setLoading(true);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const response = await fetchAuthSession();
    const { winrate_id: user_id = '' } =
      response?.tokens?.idToken?.payload || {};

    try {
      const inputData = {
        success_url: `${baseURL}/my-account/subscription`,
        cancel_url: `${baseURL}/my-account/subscription`,
        user_id,
      };
      const response = await doPost('stripe-checkout', inputData);
      window.location.href = response.data;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Error fetching purchase plan: ', err);
    }
  };

  useEffect(() => {
    fetchFreeTrialStatus();
  }, []);

  return (
    <div className="subscription-tab">
      <Row className="mt-30">
        <Typography.Title level={5}>Current Plan</Typography.Title>
      </Row>
      {userDetails?.subscription_status === 'expired' ||
      userDetails?.subscription_status === 'ended' ? (
        <Row>
          <Typography.Title
            level={4}
            className="mb-0 secondary-font-family fw-400"
          >
            No active plan
          </Typography.Title>
        </Row>
      ) : (
        <Row justify="space-between" gutter={[16, 16]} align="middle">
          <Col md={12} sm={12} xs={12} lg={12}>
            <Typography.Title level={3} className="freetrail-text mb-0">
              Free Trial
            </Typography.Title>
            <Typography.Text className="text-secondary">
              Valid until{' '}
              {dayjs(freeTrialDate?.trialEndDate).format('MMM DD, YYYY')}
            </Typography.Text>
          </Col>
          <Col md={12} sm={12} xs={12} lg={12}>
            <Row align="middle" justify="end">
              <img
                src={WinrateImages.SubscriptionIcons.hourGlassIcon}
                className="mr-10"
              />
              <Typography.Text className="text-primary fw-500">
                Ends in {freeTrialDate?.daysLeft} Days
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="mt-20">
        <Col md={24} sm={24} xs={24} lg={24}>
          <Card className="stripe-card">
            <Row>
              <Col md={14} sm={24} xs={24} lg={14}>
                <Typography.Title level={3}>Go Premium</Typography.Title>
                <img
                  className="mt-10"
                  src={WinrateImages.SubscriptionIcons.stripePlaceholder}
                />
              </Col>
              <Col md={10} sm={24} xs={24} lg={10}>
                <Typography.Title className="mb-0" level={4}>
                  $25
                </Typography.Title>
                <Typography.Text>Per month, billed annually</Typography.Text>
                <Row>
                  <Typography.Text className="text-secondary mt-10">
                    Continue accessing all premium features <br /> by upgrading
                    today
                  </Typography.Text>
                </Row>
                <Space className="mt-10" direction="vertical">
                  <Typography.Text>
                    <CheckCircleFilled
                      style={{ color: 'green', marginRight: '8px' }}
                      className="fs-20"
                    />
                    Unlimited Meeting Prep Notes
                  </Typography.Text>
                  <Typography.Text>
                    <CheckCircleFilled
                      style={{ color: 'green', marginRight: '8px' }}
                      className="fs-20"
                    />
                    CRM & Slack Integration
                  </Typography.Text>
                  <Typography.Text>
                    <CheckCircleFilled
                      style={{ color: 'green', marginRight: '8px' }}
                      className="fs-20"
                    />
                    Custom Research Insights
                  </Typography.Text>
                </Space>
                <Row>
                  {userDetails?.subscription_status === 'expired' ? (
                    <Button
                      type="primary"
                      className="purchase-btn mt-20"
                      onClick={purchasePlan}
                      loading={loading}
                    >
                      Renew now
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="purchase-btn mt-20"
                      onClick={purchasePlan}
                      loading={loading}
                    >
                      Purchase now
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PurchasePlan;
