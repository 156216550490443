import { Button } from 'antd';
import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: React.MouseEventHandler;
}

const RedButton: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <Button type="primary" className="red-btn" onClick={onClick}>
      {text}
    </Button>
  );
};

export default RedButton;
