import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../store/authSlice';
import customResearchReducer from '../store/customResearchSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customResearch: customResearchReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
