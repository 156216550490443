import {
  Avatar,
  Button,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import SearchComponent from '../../../common/SearchComponent';
import { WinrateImages } from '../../../assets/Images';
import RemoveUserModal from './RemoveUserModal';
import AssignUserModal from './AssignUserModal';

interface DataType {
  key: string;
  name: string;
  email: string;
  avatar: string;
  status: string;
  title: string;
  department: string;
}

const UsersDetails = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [assignUserModal, setAssignUserModal] = useState(false);
  const { rightArrowIcon } = WinrateImages.UsersIcons;

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: DataType) => (
        <>
          <Space>
            <Avatar src={record.avatar} />
            <div>
              <div>{text}</div>
              <div>{record.email}</div>
            </div>
          </Space>
          {hoveredRow === record.key ? (
            <Button className="action-btn">
              Actions <DownOutlined className="down-arrow-icon" />
            </Button>
          ) : null}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status: string) => (
        <Tag
          className="status-tag"
          color={status === 'Active' ? 'green' : 'red'}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      sorter: true,
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      name: 'Jacob Jones',
      email: 'nevaeh.simmons@example.com',
      avatar: 'https://placehold.co/32x32',
      status: 'Active',
      title: 'Admin',
      department: '-',
    },
    {
      key: '2',
      name: 'Jacob Jones',
      email: 'nevaeh.simmons@example.com',
      avatar: 'https://placehold.co/32x32',
      status: 'Active',
      title: 'Admin',
      department: '-',
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: () => ({
      disabled: data?.length === 1, // Disable checkbox if data length is 1
    }),
  };
  return (
    <div className="mt-24">
      <Row justify="space-between">
        <Typography.Text>Assign users to the access group.</Typography.Text>
        <PrimaryButton
          text="Assign Users"
          onClick={() => setAssignUserModal(true)}
        />
      </Row>
      <Row className="edit-search-row" align="middle">
        <SearchComponent placeholder="Search name or email address " />
        {selectedRowKeys?.length >= 1 && (
          <>
            <Typography.Text className="ml-10 ">
              <span className="fw-600">{selectedRowKeys?.length}</span>{' '}
              <span className="text-secondary">Selected</span>
            </Typography.Text>
            <img src={rightArrowIcon} alt="" className="ml-10" />
            <Button
              type="text"
              className="branding-color"
              onClick={() => setRemoveUserModal(true)}
            >
              Remove
            </Button>
          </>
        )}
      </Row>
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={data}
        onRow={(record) => {
          return {
            onMouseEnter: () => setHoveredRow(record.key),
            onMouseLeave: () => setHoveredRow(null),
          };
        }}
      />
      <RemoveUserModal
        setRemoveUserModal={setRemoveUserModal}
        removeUserModal={removeUserModal}
      />
      <AssignUserModal
        assignUserModal={assignUserModal}
        setAssignUserModal={setAssignUserModal}
      />
    </div>
  );
};

export default UsersDetails;
