import { Breadcrumb, Col, Row, Tabs } from 'antd';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyInfo from './components/MyInfo';
import Subscription from './components/Subscription';
import './MyAccount.less';

const MyAccount: React.FC = () => {
  const items = [
    {
      key: 'my_info',
      label: 'My Info',
      children: <MyInfo />,
    },
    {
      key: 'subscription',
      label: 'Subscription',
      children: <Subscription />,
    },
  ];

  const navigate = useNavigate();
  const currentTab = location.pathname.split('/')[2] || 'my_info';

  const onChange = (key: string) => {
    navigate(`/my-account/${key}`);
  };

  return (
    <>
      <Breadcrumb separator=">" className="mb-20">
        <Breadcrumb.Item>
          <Link to="/">My meetings</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>My account</Breadcrumb.Item>
      </Breadcrumb>
      <div className="myAccount">
        <Row justify={'center'}>
          <Col sm={24} md={24} lg={18} xs={24}>
            <h2>My Account</h2>

            <Tabs
              className="mt-24 setting_tabs info_tabs"
              activeKey={currentTab}
              items={items}
              onChange={onChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyAccount;
