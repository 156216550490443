import { Modal, Typography } from 'antd';
import React from 'react';
import { ImagePopupProps } from './CustomResearchTypes';

const ImagePopup: React.FC<ImagePopupProps> = ({
  img,
  title,
  desc,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      open={isOpen}
      title={''}
      closable={true}
      className="first_modal_research"
      centered
      footer={null}
      onCancel={onClose}
    >
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div>
          <img src={img} />
        </div>

        <Typography.Title level={3} className="title-font">
          {title}
        </Typography.Title>
        <Typography.Paragraph className="description-font">
          {desc}
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ImagePopup;
