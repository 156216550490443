import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import './login.less';
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  message,
  Grid,
} from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import {
  signIn,
  signOut,
  signInWithRedirect,
  fetchAuthSession,
} from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/data';
import { setUserID } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import MobileNonAuthHeader from '../../common/MobileNonAuthHeader';

const Login: React.FC = () => {
  const { authBG, sidePlaceholder, loginLogo, google } = WinrateImages.Auth;
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [loading, setLoading] = useState(false);
  type ClientSchema = any;
  const client = generateClient<ClientSchema>({ authMode: 'apiKey' });
  //for google sso
  const handleGoogleSignIn = async () => {
    try {
      // This will trigger the Google OAuth flow using Amplify
      signOut({
        global: false,
      })
        .then(async () => {
          localStorage.clear();
          await signInWithRedirect({
            provider: 'Google',
            customState: 'signIn',
          });
        })
        .catch((err: any) => message.error(err.message));
    } catch (error: any) {
      message.error(error.message);
      console.error('Error during Google Sign In', error);
    }
  };

  //for manual login
  const handleLogin = async () => {
    setLoading(true);
    signOut({
      global: false,
    })
      .then(async () => {
        localStorage.clear();
        const formValue = form.getFieldsValue();
        const emailData = formValue?.email?.toLowerCase();
        const trimedEmail = emailData.trim();

        await signIn({ username: trimedEmail, password: formValue.password })
          .then(async () => {
            const response = await fetchAuthSession();
            const { email = '', winrate_id: id = '' }: any =
              response?.tokens?.idToken?.payload || {};

            dispatch(setUserID(id));

            if (email) {
              localStorage.setItem('User', email);
            }
            const res = await client.queries.integrationStatus({
              email,
            });
            const usersDetails = JSON.parse(res.data.response);
            localStorage.setItem(
              'given_name',
              usersDetails?.userInfo?.first_name
            );
            if (!usersDetails?.userInfo?.first_name) {
              navigate('/setup-account/0');
            } else if (!usersDetails?.google) {
              navigate('/setup-account/1');
            } else {
              const token = await fetchAuthSession();
              if (token?.tokens) {
                localStorage.setItem(
                  'token',
                  token?.tokens?.accessToken as any
                );
              }
              navigate('/');
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (
              err.message === 'User needs to be authenticated to call this API.'
            ) {
              message.error('user is not confirmed');
              navigate('/verify-email', {
                state: { username: formValue?.email },
              });
            }
            message.error(err.message);
          });
      })
      .catch((err: any) => message.error(err.message));
  };

  return (
    <div className={`auth-page ${!screens.md ? 'mob-auth-page' : ''}`}>
      {!screens.md && <MobileNonAuthHeader />}

      {screens.md && <img src={authBG} alt="" className="auth-bg" />}
      <Row
        gutter={{
          xs: 0,
          sm: 0,
          md: 16,
        }}
        className="auth-container"
      >
        {/* Left Side: Login Form */}
        <Col xs={24} md={12} className="login-form-container">
          <div className="login-form">
            <div className="mob-top">
              {screens.md && (
                <img
                  src={loginLogo}
                  alt="Winrate Logo"
                  className="logo mb-20"
                />
              )}

              <div className={!screens.xl ? 'text-center' : ''}>
                <Title level={4} className="mb-0">
                  Login
                </Title>
                <Text type="secondary" style={{ color: '#595959' }}>
                  Welcome! Please enter your details.
                </Text>
              </div>

              {/* Google Login Button */}
              <Button
                icon={<img src={google} alt="" />}
                className="google-login-btn"
                block
                onClick={handleGoogleSignIn}
              >
                Sign in with Google
              </Button>

              <Divider className="login-divider">or</Divider>

              <Form
                name="login"
                layout="vertical"
                className="login-form-fields"
                requiredMark={false}
                form={form}
                onFinish={() => handleLogin()}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    {
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                >
                  <Input placeholder="you@company.com" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  className="mb-0"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                  ]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>

                <Form.Item className="forgotpassword-link">
                  <Button
                    type="text"
                    className="text-secondary"
                    onClick={() => navigate('/forgot-password')}
                    style={{ paddingRight: 0 }}
                  >
                    Forgot password
                  </Button>
                </Form.Item>

                <Form.Item className="mt-20 mb-10">
                  <PrimaryButton
                    text="Login"
                    className="login-btn"
                    loading={loading}
                  />
                </Form.Item>
              </Form>
              <Row justify="center" align="middle">
                <Text className="signup-text">
                  Don&apos;t have an account?{' '}
                </Text>
                <Button
                  className="signup-link"
                  type="text"
                  onClick={() => navigate('/signup')}
                >
                  {' '}
                  Sign Up
                </Button>
              </Row>
            </div>
            <div className="mob-bottom">
              <div className="footer-links">
                <Typography.Text className="mr-5">
                  Terms & Conditions
                </Typography.Text>
                <span className="text-secondary">• </span>
                <Typography.Text className="ml-5">
                  Privacy Policy
                </Typography.Text>
              </div>
              <Text className="signup-text fs-12 mt-10">
                © {new Date().getFullYear()}, Winrate. All Rights Reserved.
              </Text>
            </div>
          </div>
        </Col>

        {screens.md && (
          <Col xs={0} md={12} className="image-container">
            <div className="image-content">
              <img src={sidePlaceholder} alt="Right Side Illustration" />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Login;
